.caption_title_line,
.flex-caption-cricketstart .caption_title_line {
	background: url(../images/slider_caption_bg.png);
}

.slider_container {
	width: 920px;
	margin: auto;
	clear: both;
	padding: 30px 0 0;
}

.flex-caption {
	position: absolute;
	right: 0;
	bottom: 20px;
}

.flex-caption-cricket {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
}

.flex-caption-cricketstart {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 45%;
}

.flex-caption-movie {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 56%;
}

.flex-caption-preview {
	position: absolute;
	left: 0;
	top: 30%;
	width: 56%;
}

.flex-caption-rwdwinners {
	position: absolute;
	right: 8%;
	bottom: 0;
	width: 35%;
}

.caption_title_line {
	width: 315px;
	height: 66px;
	padding: 12px;
	color: #303030;
}

.flex-caption-cricket .caption_title_line {
	width: 100%;
	height: auto;
	border-radius: 0 0 4px 4px;
	padding: 9px 30px 8px;
	background: url(../images/slider_caption_bg.png);
	color: #303030;
}

.flex-caption-cricketstart .caption_title_line,
.flex-caption-movie .caption_title_line,
.flex-caption-preview .caption_title_line {
	border-radius: 0 0 4px;
	padding: 12px 20px 10px 25px;
	width: 100%;
	height: auto;
	color: #303030;
}

.flex-caption-movie .caption_title_line {
	background: url(../images/slider_caption_bluebg.png);
}

.flex-caption-preview .caption_title_line {
	background: 0 0;
}

.flex-caption-rwdwinners .caption_title_line {
	width: 100%;
	height: auto;
	border-radius: 4px 4px 0 0;
	padding: 16px 14px 10px;
	background: url(../images/rwslider_caption_bg.png);
	color: #303030;
}

.flex-caption h2,
.flex-caption-cricket h2 {
	text-shadow: 1px 1px 1px #000;
	padding: 0;
	margin: 0;
}

.flex-caption h2 {
	color: #fff;
	font-size: 17px;
	font-weight: 300;
}

.flex-caption-cricket h2 {
	color: #fff;
	font-size: 14px;
	font-weight: 700;
}

.flex-caption p {
	color: #fff;
	font-size: 17px;
	font-weight: 300;
	text-shadow: 1px 1px 1px #000;
	padding: 2px 0 0;
	margin: 0;
}

.flex-caption-cricket p,
.flex-caption-cricketstart p,
.flex-caption-movie p,
.flex-caption-preview p {
	color: #fff;
	font-weight: 600;
	text-shadow: 1px 1px 1px #000;
	margin: 0;
}

.flex-caption-cricket p {
	font-size: 13px;
	padding: 2px 0 0;
}

.flex-caption-cricketstart p,
.flex-caption-movie p {
	font-size: 16px;
	padding: 2px 0 0;
}

.flex-caption-preview p {
	font-size: 22px;
	line-height: 1.1;
	padding: 0;
}

.flex-caption-rwdwinners h2 {
	color: #2c857d;
	font-size: 20px;
	line-height: 1.1;
	font-weight: 800;
	padding: 0 0 4px;
	margin: 0;
}

.flex-caption-rwdwinners p {
	color: #3a3a3a;
	font-size: 15px;
	line-height: 1.2;
	font-weight: 400;
	padding: 0;
	margin: 0;
}

.points {
	font-weight: 700;
}

.flex-caption-preview .points,
.flex-caption-rwdwinners .points {
	font-weight: 700;
	font-size: 30px;
}

.flex-container a:active,
.slider-txt {
	float: left;
	width: 70%;
}

.flex-caption-cricketstart .slider-txt,
.flex-caption-movie .slider-txt {
	float: left;
	width: 60%;
}

.flex-caption-preview .slider-txt,
.flex-caption-rwdwinners .slider-txt {
	width: 100%;
	float: none;
}

.flex-caption-cricketstart .play-contest,
.flex-caption-movie .play-contest {
	float: right;
	width: 38%;
	text-align: right;
	padding: 20px 0 0;
	text-shadow: 2px 2px 2px #000;
}

.flex-caption-preview .play-contest,
.flex-caption-rwdwinners .play-contest {
	width: 100%;
	float: none;
	text-align: left;
	padding: 22px 0 0;
	text-shadow: 2px 2px 2px #000;
}

.play-contest {
	float: right;
	width: 20%;
	text-align: right;
	padding: 13px 0 0;
	text-shadow: 2px 2px 2px #000;
}

.flex-caption-cricketstart .play-contest a,
.flex-caption-movie .play-contest a,
.flex-caption-preview .play-contest a,
.flex-caption-rwdwinners .play-contest a,
.play-contest a {
	color: #fff;
	line-height: 1;
	font-weight: 600;
	text-decoration: none;
	border-radius: 2px;
	text-shadow: 0 1px 0 #000;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

.play-contest a {
	background-color: #1176da;
	font-size: 12px;
	padding: 6px 12px;
}

.play-contest a:hover {
	background-color: #f3352c;
}

.flex-caption-cricketstart .play-contest a,
.flex-caption-movie .play-contest a,
.flex-caption-preview .play-contest a,
.flex-caption-rwdwinners .play-contest a {
	background-color: #f44a18;
	font-size: 13px;
	padding: 6px 18px;
}

.flex-caption-cricketstart .play-contest a:hover,
.flex-caption-movie .play-contest a:hover,
.flex-caption-preview .play-contest a:hover {
	background-color: #1176da;
}

.flexslider a:active {
	outline: 0;
}

.flex-control-nav,
.flex-direction-nav,
.slides {
	margin: 0;
	padding: 0;
	list-style: none;
}

.flexslider {
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	zoom: 1;
}

.flexslider .slides > li {
	display: none;
	position: relative;
}

.flexslider .slides img,
.no-js .slides > li:first-child,
.slides:after,
html[xmlns] .slides {
	display: block;
}

.flexslider .slides img {
	max-width: 100%;
	width: 100%;
	border-radius: 4px;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

.slides:after {
	content: ".";
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

* html .slides {
	height: 1%;
}

.flexslider .slides {
	zoom: 1;
}

.flex-container {
	zoom: 1;
	position: relative;
}

.flex-direction-nav li a {
	width: 45px;
	height: 42px;
	margin: 0;
	display: block;
	position: absolute;
	right: 11px;
	cursor: pointer;
	text-indent: -9999px;
}

.flex-direction-nav li a.next {
	bottom: 184px;
}

.flex-direction-nav li a.prev {
	bottom: 141px;
}

.flex-direction-nav li a.disabled {
	opacity: 0.3;
	filter: alpha(opacity=30);
	cursor: default;
}

.flex-control-nav {
	width: 100%;
	position: absolute;
	bottom: -30px;
	text-align: center;
}

.flex-control-nav li {
	margin: 0 0 0 5px;
	display: inline-block;
	zoom: 1;
}

.flex-control-nav li:first-child {
	margin: 0;
}

.flex-control-nav li a {
	width: 13px;
	height: 13px;
	display: block;
	cursor: pointer;
	text-indent: -9999px;
}

.flex-control-nav li a:hover {
	background-position: 0 -13px;
}

.flex-control-nav li a.active {
	background-position: 0 -26px;
	cursor: default;
}

@media screen and (max-width: 1000px) {
	.slider_container {
		width: 90%;
		margin: 0 0 0 2%;
		clear: both;
		background: 0 0;
		padding: 30px 0 0;
	}
	.flexslider {
		width: 100%;
		margin: 0;
		padding: 0;
		background: 0 0;
	}
	.flex-direction-nav li a {
		width: 45px;
		height: 42px;
		margin: 0;
		display: block;
		position: absolute;
		right: -10px;
		cursor: pointer;
		text-indent: -9999px;
	}
	.flex-direction-nav li a.next {
		bottom: 85px;
	}
	.flex-direction-nav li a.prev {
		bottom: 40px;
	}
	.flex-caption,
	.flex-caption-cricket,
	.flex-caption-cricketstart,
	.flex-caption-movie,
	.flex-caption-preview,
	.flex-caption-rwdwinners {
		display: none;
	}
}
