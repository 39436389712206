.modal {
	position: absolute;
}

#signup {
	margin: 30px auto;
}

/* .forgot-password {
	text-align: center;
	padding: 12px 0 0;
} */

.signuprow .fade {
	opacity: 1;
}

.newuser-signup {
	display: inline-block;
	width: 100%;
	text-align: center;
	color: #000;
	font-size: 12px;
}

.newuser-signup p {
	color: #c02c39;
	font-size: 13px;
	margin-left: 0;
	padding: 0 0 10px;
}

.forgot-password a {
	color: #2f63cf;
}

.signup-container {
	margin: 30px auto;
	position: relative;
	width: 85%;
}

.signuprow {
	margin: 0;
	border-radius: 3px;
}

.signup-container .modal-header {
	min-height: 16.43px;
	padding: 15px;
	border-bottom: none;
	float: right;
	position: absolute;
	right: 1px;
	top: -8px;
	z-index: 10;
}

.signup-container .modal-header .close {
	float: right;
	font-size: 35px;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	opacity: 1;
}

.signup-left-main,
.signup-right-main {
	float: left;
	width: 50%;
	margin: 0;
}

.bottom-base,
.bottom-base p,
.bottom-top,
.bottom-top p,
.leftbottom,
.textxl {
	line-height: 1.2;
}

.signup-left {
	background: url(../images/signupleftbg.jpg) repeat-y;
	background-size: contain;
	padding: 50px 50px 25px;
	border-radius: 3px 0 0 3px;
	min-height: 690px;
}

.signup-right {
	background: url(../images/signupright-bg.jpg) no-repeat;
	background-size: cover;
	border-radius: 0 3px 3px 0;
	min-height: 690px;
}

.lefttop {
	padding: 0 50px 90px;
}

.lefttop .add {
	margin: 0;
	padding: 0;
	width: 100%;
}

.lefttop .add img {
	width: 100%;
}

.logo-small {
	padding: 0 0 8px;
}

.leftbottom {
	padding: 0 20px 40px 0;
	color: #fff;
	font-weight: 300;
}

.bottom-top {
	padding: 0 0 65px;
}

.bottom-top p {
	font-size: 20px;
}

.textbig {
	font-size: 26px;
}

.textmedium {
	font-size: 25px;
}

.textlarge {
	font-size: 30px;
}

.textxl {
	font-size: 31px;
}

.bottom-base {
	padding: 0;
}

.bottom-base p {
	font-size: 19px;
}

.signup-inner {
	padding: 120px 0 130px;
}

.signup-bg {
	width: 60%;
	margin: 0 auto;
	background: url(../images/sign-upbg.png);
	padding: 20px 15px;
	border-radius: 3px;
}

.azm-btn,
.btn-started {
	width: 100%;
	font-weight: 600;
}

.signingoogle {
	padding: 0 0 9px;
}

.signinfacebook,
.signuptitle {
	padding: 0 0 10px;
}

.signuptitle {
	font-weight: 300;
	font-size: 13px;
	color: #000;
	text-align: center;
}

.signupform {
	margin: 0;
}

.mck-form-group {
	margin-bottom: 16px;
}

.input-group-addon {
	background-color: #fff;
	border: none;
	padding: 0 12px;
}

.input-group {
	background-color: #fff;
	padding: 2px;
	border-radius: 4px;
	border: 1px solid #d6dce7;
}

.signup-form .input-group-addon:first-child {
	border-right: 0;
}

.mck-form-group .form-control {
	border: none;
	border-left: 1px solid #d6dce7;
	box-shadow: none;
	padding: 0 12px;
}

.getstarted {
	padding: 5px 0 0;
}

.btn-started {
	background-color: #001563;
	color: #fff;
	border-bottom: 2px solid #001563;
	padding: 10px 20px;
	font-size: 15px;
	line-height: 1;
}

.btn-started:hover {
	background-color: #f23209;
	color: #fff;
}

.azm-google-plus {
	background: #dd4b39;
}

.azm-border-bottom {
	padding-top: 12px;
	border-bottom: 4px solid rgba(0, 0, 0, 0.1);
}

.azm-social {
	margin: 0;
	padding: 0;
	display: inline-block;
	color: #fff;
	text-align: left;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	-o-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
}

.signup-bg a,
.title-center {
	text-align: center;
}

.azm-btn {
	padding: 6px 12px;
	font-size: 13px;
	line-height: 21px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.azm-btn i {
	padding-right: 27px;
	font-size: 21px;
	line-height: 21px;
	vertical-align: top;
	position: relative;
}

.azm-btn i:after {
	content: "";
	position: absolute;
	top: -7px;
	left: 70%;
	width: 1px;
	height: 34px;
	background: #e5e5e5;
}

.azm-social:active,
.azm-social:focus,
.azm-social:hover {
	outline: 0;
	opacity: 0.92;
	color: #fff;
}

.azm-facebook {
	background: #4862a3;
}

.title-centergp {
	margin-left: 37px;
}

.title-centerfb {
	margin-left: 43px;
}

.signup-bg a {
	font-size: 13px;
	padding: 9px 8px 9px 14px;
	font-weight: 600;
}

.signup-bg .btn-social > :first-child {
	top: 2px;
	width: 38px;
}

@media (max-width: 768px) {
	.signup-left-main,
	.signup-right-main {
		float: none;
		width: 100%;
		border-radius: 3px;
	}
	.signup-left,
	.signup-right {
		min-height: inherit;
	}
	.bottom-base p,
	.bottom-top p {
		font-size: 16px;
		line-height: 1.2;
	}
	.signuprow {
		margin: 0;
		border-radius: 3px;
		display: block;
	}
	.signup-right-main {
		margin: 10px auto;
	}
	.signup-left-main {
		padding: 0;
	}
	.signup-left {
		padding: 10px;
	}
	.bottom-top {
		padding: 0 0 25px;
		line-height: 1.2;
	}
	.signup-bg {
		width: 95%;
		margin: 0 auto;
		padding: 15x 12px;
		border-radius: 3px;
	}
	.signup-inner {
		padding: 28px 0 50px;
	}
	.lefttop {
		padding: 0 0 20px;
	}
	.leftbottom {
		padding: 0 0 30px;
		text-align: center;
	}
	.logo-small img {
		width: 80%;
	}
	.textbig {
		font-size: 22px;
	}
	.textmedium {
		font-size: 21px;
	}
	.textlarge {
		font-size: 22px;
	}
	.textxl {
		font-size: 27px;
	}
	.title-centergp {
		margin-left: 10px;
	}
	.title-centerfb {
		margin-left: 16px;
	}
	.signup-container {
		margin: 30px auto;
		width: 90%;
	}
}
