@media only screen and (max-width: 1199px) {
	.tambola-info {
		width: 75%;
		margin: 0px auto;
	}
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 639px) {
}

@media only screen and (max-width: 480px) {
}
@media only screen and (max-width: 315px) {
}
