.btn-social,
.btn-social-icon {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.btn-adn.active,
.btn-adn:active,
.btn-bitbucket.active,
.btn-bitbucket:active,
.btn-dropbox.active,
.btn-dropbox:active,
.btn-facebook.active,
.btn-facebook:active,
.btn-flickr.active,
.btn-flickr:active,
.btn-foursquare.active,
.btn-foursquare:active,
.btn-github.active,
.btn-github:active,
.btn-google.active,
.btn-google:active,
.btn-instagram.active,
.btn-instagram:active,
.btn-linkedin.active,
.btn-linkedin:active,
.btn-odnoklassniki.active,
.btn-odnoklassniki:active,
.btn-openid.active,
.btn-openid:active,
.btn-pinterest.active,
.btn-pinterest:active,
.btn-reddit.active,
.btn-reddit:active,
.btn-soundcloud.active,
.btn-soundcloud:active,
.btn-tumblr.active,
.btn-tumblr:active,
.btn-twitter.active,
.btn-twitter:active,
.btn-vimeo.active,
.btn-vimeo:active,
.btn-vk.active,
.btn-vk:active,
.btn-yahoo.active,
.btn-yahoo:active,
.open > .dropdown-toggle.btn-adn,
.open > .dropdown-toggle.btn-bitbucket,
.open > .dropdown-toggle.btn-dropbox,
.open > .dropdown-toggle.btn-facebook,
.open > .dropdown-toggle.btn-flickr,
.open > .dropdown-toggle.btn-foursquare,
.open > .dropdown-toggle.btn-github,
.open > .dropdown-toggle.btn-google,
.open > .dropdown-toggle.btn-instagram,
.open > .dropdown-toggle.btn-linkedin,
.open > .dropdown-toggle.btn-odnoklassniki,
.open > .dropdown-toggle.btn-openid,
.open > .dropdown-toggle.btn-pinterest,
.open > .dropdown-toggle.btn-reddit,
.open > .dropdown-toggle.btn-soundcloud,
.open > .dropdown-toggle.btn-tumblr,
.open > .dropdown-toggle.btn-twitter,
.open > .dropdown-toggle.btn-vimeo,
.open > .dropdown-toggle.btn-vk,
.open > .dropdown-toggle.btn-yahoo {
	background-image: none;
}

.btn-social {
	position: relative;
	padding-left: 44px;
	text-align: left;
}

.btn-social > :first-child {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 32px;
	line-height: 34px;
	font-size: 1.6em;
	text-align: center;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.btn-social.btn-lg {
	padding-left: 61px;
}

.btn-social.btn-lg > :first-child {
	line-height: 45px;
	width: 45px;
	font-size: 1.8em;
}

.btn-social.btn-sm {
	padding-left: 38px;
}

.btn-social.btn-sm > :first-child {
	line-height: 28px;
	width: 28px;
	font-size: 1.4em;
}

.btn-social.btn-xs {
	padding-left: 30px;
}

.btn-social.btn-xs > :first-child {
	line-height: 20px;
	width: 20px;
	font-size: 1.2em;
}

.btn-social-icon {
	position: relative;
	text-align: left;
	height: 34px;
	width: 34px;
	padding: 0;
}

.btn-social-icon > :first-child {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	line-height: 34px;
	font-size: 1.6em;
}

.btn-social-icon.btn-lg > :first-child {
	line-height: 45px;
	width: 45px;
	font-size: 1.8em;
}

.btn-social-icon.btn-sm > :first-child {
	line-height: 28px;
	width: 28px;
	font-size: 1.4em;
}

.btn-social-icon.btn-xs > :first-child {
	line-height: 20px;
	width: 20px;
	font-size: 1.2em;
}

.btn-social-icon > :first-child {
	border: none;
	text-align: center;
	width: 100% !important;
}

.btn-social-icon.btn-lg {
	height: 45px;
	width: 45px;
	padding-left: 0;
	padding-right: 0;
}

.btn-social-icon.btn-sm {
	height: 30px;
	width: 30px;
	padding-left: 0;
	padding-right: 0;
}

.btn-social-icon.btn-xs {
	height: 22px;
	width: 22px;
	padding-left: 0;
	padding-right: 0;
}

.btn-adn {
	color: #fff;
	background-color: #d87a68;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn.active,
.btn-adn.focus,
.btn-adn:active,
.btn-adn:focus,
.btn-adn:hover,
.open > .dropdown-toggle.btn-adn {
	color: #fff;
	background-color: #ce563f;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn.active.focus,
.btn-adn.active:focus,
.btn-adn.active:hover,
.btn-adn:active.focus,
.btn-adn:active:focus,
.btn-adn:active:hover,
.open > .dropdown-toggle.btn-adn.focus,
.open > .dropdown-toggle.btn-adn:focus,
.open > .dropdown-toggle.btn-adn:hover {
	color: #fff;
	background-color: #b94630;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn.disabled,
.btn-adn.disabled.active,
.btn-adn.disabled.focus,
.btn-adn.disabled:active,
.btn-adn.disabled:focus,
.btn-adn.disabled:hover,
.btn-adn[disabled],
.btn-adn[disabled].active,
.btn-adn[disabled].focus,
.btn-adn[disabled]:active,
.btn-adn[disabled]:focus,
.btn-adn[disabled]:hover,
fieldset[disabled] .btn-adn,
fieldset[disabled] .btn-adn.active,
fieldset[disabled] .btn-adn.focus,
fieldset[disabled] .btn-adn:active,
fieldset[disabled] .btn-adn:focus,
fieldset[disabled] .btn-adn:hover {
	background-color: #d87a68;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn .badge {
	color: #d87a68;
	background-color: #fff;
}

.btn-bitbucket {
	color: #fff;
	background-color: #205081;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket.active,
.btn-bitbucket.focus,
.btn-bitbucket:active,
.btn-bitbucket:focus,
.btn-bitbucket:hover,
.open > .dropdown-toggle.btn-bitbucket {
	color: #fff;
	background-color: #163758;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket.active.focus,
.btn-bitbucket.active:focus,
.btn-bitbucket.active:hover,
.btn-bitbucket:active.focus,
.btn-bitbucket:active:focus,
.btn-bitbucket:active:hover,
.open > .dropdown-toggle.btn-bitbucket.focus,
.open > .dropdown-toggle.btn-bitbucket:focus,
.open > .dropdown-toggle.btn-bitbucket:hover {
	color: #fff;
	background-color: #0f253c;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket.disabled,
.btn-bitbucket.disabled.active,
.btn-bitbucket.disabled.focus,
.btn-bitbucket.disabled:active,
.btn-bitbucket.disabled:focus,
.btn-bitbucket.disabled:hover,
.btn-bitbucket[disabled],
.btn-bitbucket[disabled].active,
.btn-bitbucket[disabled].focus,
.btn-bitbucket[disabled]:active,
.btn-bitbucket[disabled]:focus,
.btn-bitbucket[disabled]:hover,
fieldset[disabled] .btn-bitbucket,
fieldset[disabled] .btn-bitbucket.active,
fieldset[disabled] .btn-bitbucket.focus,
fieldset[disabled] .btn-bitbucket:active,
fieldset[disabled] .btn-bitbucket:focus,
fieldset[disabled] .btn-bitbucket:hover {
	background-color: #205081;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket .badge {
	color: #205081;
	background-color: #fff;
}

.btn-dropbox {
	color: #fff;
	background-color: #1087dd;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox.active,
.btn-dropbox.focus,
.btn-dropbox:active,
.btn-dropbox:focus,
.btn-dropbox:hover,
.open > .dropdown-toggle.btn-dropbox {
	color: #fff;
	background-color: #0d6aad;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox.active.focus,
.btn-dropbox.active:focus,
.btn-dropbox.active:hover,
.btn-dropbox:active.focus,
.btn-dropbox:active:focus,
.btn-dropbox:active:hover,
.open > .dropdown-toggle.btn-dropbox.focus,
.open > .dropdown-toggle.btn-dropbox:focus,
.open > .dropdown-toggle.btn-dropbox:hover {
	color: #fff;
	background-color: #0a568c;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox.disabled,
.btn-dropbox.disabled.active,
.btn-dropbox.disabled.focus,
.btn-dropbox.disabled:active,
.btn-dropbox.disabled:focus,
.btn-dropbox.disabled:hover,
.btn-dropbox[disabled],
.btn-dropbox[disabled].active,
.btn-dropbox[disabled].focus,
.btn-dropbox[disabled]:active,
.btn-dropbox[disabled]:focus,
.btn-dropbox[disabled]:hover,
fieldset[disabled] .btn-dropbox,
fieldset[disabled] .btn-dropbox.active,
fieldset[disabled] .btn-dropbox.focus,
fieldset[disabled] .btn-dropbox:active,
fieldset[disabled] .btn-dropbox:focus,
fieldset[disabled] .btn-dropbox:hover {
	background-color: #1087dd;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox .badge {
	color: #1087dd;
	background-color: #fff;
}

.btn-facebook {
	color: #fff;
	background-color: #3b5998;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook.active,
.btn-facebook.focus,
.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover,
.open > .dropdown-toggle.btn-facebook {
	color: #fff;
	background-color: #2d4373;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook.active.focus,
.btn-facebook.active:focus,
.btn-facebook.active:hover,
.btn-facebook:active.focus,
.btn-facebook:active:focus,
.btn-facebook:active:hover,
.open > .dropdown-toggle.btn-facebook.focus,
.open > .dropdown-toggle.btn-facebook:focus,
.open > .dropdown-toggle.btn-facebook:hover {
	color: #fff;
	background-color: #23345a;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook.disabled,
.btn-facebook.disabled.active,
.btn-facebook.disabled.focus,
.btn-facebook.disabled:active,
.btn-facebook.disabled:focus,
.btn-facebook.disabled:hover,
.btn-facebook[disabled],
.btn-facebook[disabled].active,
.btn-facebook[disabled].focus,
.btn-facebook[disabled]:active,
.btn-facebook[disabled]:focus,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook,
fieldset[disabled] .btn-facebook.active,
fieldset[disabled] .btn-facebook.focus,
fieldset[disabled] .btn-facebook:active,
fieldset[disabled] .btn-facebook:focus,
fieldset[disabled] .btn-facebook:hover {
	background-color: #3b5998;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook .badge {
	color: #3b5998;
	background-color: #fff;
}

.btn-flickr {
	color: #fff;
	background-color: #ff0084;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr.active,
.btn-flickr.focus,
.btn-flickr:active,
.btn-flickr:focus,
.btn-flickr:hover,
.open > .dropdown-toggle.btn-flickr {
	color: #fff;
	background-color: #cc006a;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr.active.focus,
.btn-flickr.active:focus,
.btn-flickr.active:hover,
.btn-flickr:active.focus,
.btn-flickr:active:focus,
.btn-flickr:active:hover,
.open > .dropdown-toggle.btn-flickr.focus,
.open > .dropdown-toggle.btn-flickr:focus,
.open > .dropdown-toggle.btn-flickr:hover {
	color: #fff;
	background-color: #a80057;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr.disabled,
.btn-flickr.disabled.active,
.btn-flickr.disabled.focus,
.btn-flickr.disabled:active,
.btn-flickr.disabled:focus,
.btn-flickr.disabled:hover,
.btn-flickr[disabled],
.btn-flickr[disabled].active,
.btn-flickr[disabled].focus,
.btn-flickr[disabled]:active,
.btn-flickr[disabled]:focus,
.btn-flickr[disabled]:hover,
fieldset[disabled] .btn-flickr,
fieldset[disabled] .btn-flickr.active,
fieldset[disabled] .btn-flickr.focus,
fieldset[disabled] .btn-flickr:active,
fieldset[disabled] .btn-flickr:focus,
fieldset[disabled] .btn-flickr:hover {
	background-color: #ff0084;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr .badge {
	color: #ff0084;
	background-color: #fff;
}

.btn-foursquare {
	color: #fff;
	background-color: #f94877;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare.active,
.btn-foursquare.focus,
.btn-foursquare:active,
.btn-foursquare:focus,
.btn-foursquare:hover,
.open > .dropdown-toggle.btn-foursquare {
	color: #fff;
	background-color: #f71752;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare.active.focus,
.btn-foursquare.active:focus,
.btn-foursquare.active:hover,
.btn-foursquare:active.focus,
.btn-foursquare:active:focus,
.btn-foursquare:active:hover,
.open > .dropdown-toggle.btn-foursquare.focus,
.open > .dropdown-toggle.btn-foursquare:focus,
.open > .dropdown-toggle.btn-foursquare:hover {
	color: #fff;
	background-color: #e30742;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare.disabled,
.btn-foursquare.disabled.active,
.btn-foursquare.disabled.focus,
.btn-foursquare.disabled:active,
.btn-foursquare.disabled:focus,
.btn-foursquare.disabled:hover,
.btn-foursquare[disabled],
.btn-foursquare[disabled].active,
.btn-foursquare[disabled].focus,
.btn-foursquare[disabled]:active,
.btn-foursquare[disabled]:focus,
.btn-foursquare[disabled]:hover,
fieldset[disabled] .btn-foursquare,
fieldset[disabled] .btn-foursquare.active,
fieldset[disabled] .btn-foursquare.focus,
fieldset[disabled] .btn-foursquare:active,
fieldset[disabled] .btn-foursquare:focus,
fieldset[disabled] .btn-foursquare:hover {
	background-color: #f94877;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare .badge {
	color: #f94877;
	background-color: #fff;
}

.btn-github {
	color: #fff;
	background-color: #444;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-github.active,
.btn-github.focus,
.btn-github:active,
.btn-github:focus,
.btn-github:hover,
.open > .dropdown-toggle.btn-github {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-github.active.focus,
.btn-github.active:focus,
.btn-github.active:hover,
.btn-github:active.focus,
.btn-github:active:focus,
.btn-github:active:hover,
.open > .dropdown-toggle.btn-github.focus,
.open > .dropdown-toggle.btn-github:focus,
.open > .dropdown-toggle.btn-github:hover {
	color: #fff;
	background-color: #191919;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-github.disabled,
.btn-github.disabled.active,
.btn-github.disabled.focus,
.btn-github.disabled:active,
.btn-github.disabled:focus,
.btn-github.disabled:hover,
.btn-github[disabled],
.btn-github[disabled].active,
.btn-github[disabled].focus,
.btn-github[disabled]:active,
.btn-github[disabled]:focus,
.btn-github[disabled]:hover,
fieldset[disabled] .btn-github,
fieldset[disabled] .btn-github.active,
fieldset[disabled] .btn-github.focus,
fieldset[disabled] .btn-github:active,
fieldset[disabled] .btn-github:focus,
fieldset[disabled] .btn-github:hover {
	background-color: #444;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-github .badge {
	color: #444;
	background-color: #fff;
}

.btn-google {
	color: #fff;
	background-color: #dd4b39;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-google.active,
.btn-google.focus,
.btn-google:active,
.btn-google:focus,
.btn-google:hover,
.open > .dropdown-toggle.btn-google {
	color: #fff;
	background-color: #c23321;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-google.active.focus,
.btn-google.active:focus,
.btn-google.active:hover,
.btn-google:active.focus,
.btn-google:active:focus,
.btn-google:active:hover,
.open > .dropdown-toggle.btn-google.focus,
.open > .dropdown-toggle.btn-google:focus,
.open > .dropdown-toggle.btn-google:hover {
	color: #fff;
	background-color: #a32b1c;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-google.disabled,
.btn-google.disabled.active,
.btn-google.disabled.focus,
.btn-google.disabled:active,
.btn-google.disabled:focus,
.btn-google.disabled:hover,
.btn-google[disabled],
.btn-google[disabled].active,
.btn-google[disabled].focus,
.btn-google[disabled]:active,
.btn-google[disabled]:focus,
.btn-google[disabled]:hover,
fieldset[disabled] .btn-google,
fieldset[disabled] .btn-google.active,
fieldset[disabled] .btn-google.focus,
fieldset[disabled] .btn-google:active,
fieldset[disabled] .btn-google:focus,
fieldset[disabled] .btn-google:hover {
	background-color: #dd4b39;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-google .badge {
	color: #dd4b39;
	background-color: #fff;
}

.btn-instagram {
	color: #fff;
	background-color: #3f729b;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram.active,
.btn-instagram.focus,
.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover,
.open > .dropdown-toggle.btn-instagram {
	color: #fff;
	background-color: #305777;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram.active.focus,
.btn-instagram.active:focus,
.btn-instagram.active:hover,
.btn-instagram:active.focus,
.btn-instagram:active:focus,
.btn-instagram:active:hover,
.open > .dropdown-toggle.btn-instagram.focus,
.open > .dropdown-toggle.btn-instagram:focus,
.open > .dropdown-toggle.btn-instagram:hover {
	color: #fff;
	background-color: #26455d;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram.disabled,
.btn-instagram.disabled.active,
.btn-instagram.disabled.focus,
.btn-instagram.disabled:active,
.btn-instagram.disabled:focus,
.btn-instagram.disabled:hover,
.btn-instagram[disabled],
.btn-instagram[disabled].active,
.btn-instagram[disabled].focus,
.btn-instagram[disabled]:active,
.btn-instagram[disabled]:focus,
.btn-instagram[disabled]:hover,
fieldset[disabled] .btn-instagram,
fieldset[disabled] .btn-instagram.active,
fieldset[disabled] .btn-instagram.focus,
fieldset[disabled] .btn-instagram:active,
fieldset[disabled] .btn-instagram:focus,
fieldset[disabled] .btn-instagram:hover {
	background-color: #3f729b;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram .badge {
	color: #3f729b;
	background-color: #fff;
}

.btn-linkedin {
	color: #fff;
	background-color: #007bb6;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin.active,
.btn-linkedin.focus,
.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover,
.open > .dropdown-toggle.btn-linkedin {
	color: #fff;
	background-color: #005983;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin.active.focus,
.btn-linkedin.active:focus,
.btn-linkedin.active:hover,
.btn-linkedin:active.focus,
.btn-linkedin:active:focus,
.btn-linkedin:active:hover,
.open > .dropdown-toggle.btn-linkedin.focus,
.open > .dropdown-toggle.btn-linkedin:focus,
.open > .dropdown-toggle.btn-linkedin:hover {
	color: #fff;
	background-color: #00405f;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin.disabled,
.btn-linkedin.disabled.active,
.btn-linkedin.disabled.focus,
.btn-linkedin.disabled:active,
.btn-linkedin.disabled:focus,
.btn-linkedin.disabled:hover,
.btn-linkedin[disabled],
.btn-linkedin[disabled].active,
.btn-linkedin[disabled].focus,
.btn-linkedin[disabled]:active,
.btn-linkedin[disabled]:focus,
.btn-linkedin[disabled]:hover,
fieldset[disabled] .btn-linkedin,
fieldset[disabled] .btn-linkedin.active,
fieldset[disabled] .btn-linkedin.focus,
fieldset[disabled] .btn-linkedin:active,
fieldset[disabled] .btn-linkedin:focus,
fieldset[disabled] .btn-linkedin:hover {
	background-color: #007bb6;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin .badge {
	color: #007bb6;
	background-color: #fff;
}

.btn-microsoft {
	color: #fff;
	background-color: #2672ec;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft.active,
.btn-microsoft.focus,
.btn-microsoft:active,
.btn-microsoft:focus,
.btn-microsoft:hover,
.open > .dropdown-toggle.btn-microsoft {
	color: #fff;
	background-color: #125acd;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft.active.focus,
.btn-microsoft.active:focus,
.btn-microsoft.active:hover,
.btn-microsoft:active.focus,
.btn-microsoft:active:focus,
.btn-microsoft:active:hover,
.open > .dropdown-toggle.btn-microsoft.focus,
.open > .dropdown-toggle.btn-microsoft:focus,
.open > .dropdown-toggle.btn-microsoft:hover {
	color: #fff;
	background-color: #0f4bac;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft.active,
.btn-microsoft:active,
.open > .dropdown-toggle.btn-microsoft {
	background-image: none;
}

.btn-microsoft.disabled,
.btn-microsoft.disabled.active,
.btn-microsoft.disabled.focus,
.btn-microsoft.disabled:active,
.btn-microsoft.disabled:focus,
.btn-microsoft.disabled:hover,
.btn-microsoft[disabled],
.btn-microsoft[disabled].active,
.btn-microsoft[disabled].focus,
.btn-microsoft[disabled]:active,
.btn-microsoft[disabled]:focus,
.btn-microsoft[disabled]:hover,
fieldset[disabled] .btn-microsoft,
fieldset[disabled] .btn-microsoft.active,
fieldset[disabled] .btn-microsoft.focus,
fieldset[disabled] .btn-microsoft:active,
fieldset[disabled] .btn-microsoft:focus,
fieldset[disabled] .btn-microsoft:hover {
	background-color: #2672ec;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft .badge {
	color: #2672ec;
	background-color: #fff;
}

.btn-odnoklassniki {
	color: #fff;
	background-color: #f4731c;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki.active,
.btn-odnoklassniki.focus,
.btn-odnoklassniki:active,
.btn-odnoklassniki:focus,
.btn-odnoklassniki:hover,
.open > .dropdown-toggle.btn-odnoklassniki {
	color: #fff;
	background-color: #d35b0a;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki.active.focus,
.btn-odnoklassniki.active:focus,
.btn-odnoklassniki.active:hover,
.btn-odnoklassniki:active.focus,
.btn-odnoklassniki:active:focus,
.btn-odnoklassniki:active:hover,
.open > .dropdown-toggle.btn-odnoklassniki.focus,
.open > .dropdown-toggle.btn-odnoklassniki:focus,
.open > .dropdown-toggle.btn-odnoklassniki:hover {
	color: #fff;
	background-color: #b14c09;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki.disabled,
.btn-odnoklassniki.disabled.active,
.btn-odnoklassniki.disabled.focus,
.btn-odnoklassniki.disabled:active,
.btn-odnoklassniki.disabled:focus,
.btn-odnoklassniki.disabled:hover,
.btn-odnoklassniki[disabled],
.btn-odnoklassniki[disabled].active,
.btn-odnoklassniki[disabled].focus,
.btn-odnoklassniki[disabled]:active,
.btn-odnoklassniki[disabled]:focus,
.btn-odnoklassniki[disabled]:hover,
fieldset[disabled] .btn-odnoklassniki,
fieldset[disabled] .btn-odnoklassniki.active,
fieldset[disabled] .btn-odnoklassniki.focus,
fieldset[disabled] .btn-odnoklassniki:active,
fieldset[disabled] .btn-odnoklassniki:focus,
fieldset[disabled] .btn-odnoklassniki:hover {
	background-color: #f4731c;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki .badge {
	color: #f4731c;
	background-color: #fff;
}

.btn-openid {
	color: #fff;
	background-color: #f7931e;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid.active,
.btn-openid.focus,
.btn-openid:active,
.btn-openid:focus,
.btn-openid:hover,
.open > .dropdown-toggle.btn-openid {
	color: #fff;
	background-color: #da7908;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid.active.focus,
.btn-openid.active:focus,
.btn-openid.active:hover,
.btn-openid:active.focus,
.btn-openid:active:focus,
.btn-openid:active:hover,
.open > .dropdown-toggle.btn-openid.focus,
.open > .dropdown-toggle.btn-openid:focus,
.open > .dropdown-toggle.btn-openid:hover {
	color: #fff;
	background-color: #b86607;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid.disabled,
.btn-openid.disabled.active,
.btn-openid.disabled.focus,
.btn-openid.disabled:active,
.btn-openid.disabled:focus,
.btn-openid.disabled:hover,
.btn-openid[disabled],
.btn-openid[disabled].active,
.btn-openid[disabled].focus,
.btn-openid[disabled]:active,
.btn-openid[disabled]:focus,
.btn-openid[disabled]:hover,
fieldset[disabled] .btn-openid,
fieldset[disabled] .btn-openid.active,
fieldset[disabled] .btn-openid.focus,
fieldset[disabled] .btn-openid:active,
fieldset[disabled] .btn-openid:focus,
fieldset[disabled] .btn-openid:hover {
	background-color: #f7931e;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid .badge {
	color: #f7931e;
	background-color: #fff;
}

.btn-pinterest {
	color: #fff;
	background-color: #cb2027;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest.active,
.btn-pinterest.focus,
.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover,
.open > .dropdown-toggle.btn-pinterest {
	color: #fff;
	background-color: #9f191f;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest.active.focus,
.btn-pinterest.active:focus,
.btn-pinterest.active:hover,
.btn-pinterest:active.focus,
.btn-pinterest:active:focus,
.btn-pinterest:active:hover,
.open > .dropdown-toggle.btn-pinterest.focus,
.open > .dropdown-toggle.btn-pinterest:focus,
.open > .dropdown-toggle.btn-pinterest:hover {
	color: #fff;
	background-color: #801419;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest.disabled,
.btn-pinterest.disabled.active,
.btn-pinterest.disabled.focus,
.btn-pinterest.disabled:active,
.btn-pinterest.disabled:focus,
.btn-pinterest.disabled:hover,
.btn-pinterest[disabled],
.btn-pinterest[disabled].active,
.btn-pinterest[disabled].focus,
.btn-pinterest[disabled]:active,
.btn-pinterest[disabled]:focus,
.btn-pinterest[disabled]:hover,
fieldset[disabled] .btn-pinterest,
fieldset[disabled] .btn-pinterest.active,
fieldset[disabled] .btn-pinterest.focus,
fieldset[disabled] .btn-pinterest:active,
fieldset[disabled] .btn-pinterest:focus,
fieldset[disabled] .btn-pinterest:hover {
	background-color: #cb2027;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest .badge {
	color: #cb2027;
	background-color: #fff;
}

.btn-reddit {
	color: #000;
	background-color: #eff7ff;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit.active,
.btn-reddit.focus,
.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover,
.open > .dropdown-toggle.btn-reddit {
	color: #000;
	background-color: #bcddff;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit.active.focus,
.btn-reddit.active:focus,
.btn-reddit.active:hover,
.btn-reddit:active.focus,
.btn-reddit:active:focus,
.btn-reddit:active:hover,
.open > .dropdown-toggle.btn-reddit.focus,
.open > .dropdown-toggle.btn-reddit:focus,
.open > .dropdown-toggle.btn-reddit:hover {
	color: #000;
	background-color: #98ccff;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit.disabled,
.btn-reddit.disabled.active,
.btn-reddit.disabled.focus,
.btn-reddit.disabled:active,
.btn-reddit.disabled:focus,
.btn-reddit.disabled:hover,
.btn-reddit[disabled],
.btn-reddit[disabled].active,
.btn-reddit[disabled].focus,
.btn-reddit[disabled]:active,
.btn-reddit[disabled]:focus,
.btn-reddit[disabled]:hover,
fieldset[disabled] .btn-reddit,
fieldset[disabled] .btn-reddit.active,
fieldset[disabled] .btn-reddit.focus,
fieldset[disabled] .btn-reddit:active,
fieldset[disabled] .btn-reddit:focus,
fieldset[disabled] .btn-reddit:hover {
	background-color: #eff7ff;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit .badge {
	color: #eff7ff;
	background-color: #000;
}

.btn-soundcloud {
	color: #fff;
	background-color: #f50;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud.active,
.btn-soundcloud.focus,
.btn-soundcloud:active,
.btn-soundcloud:focus,
.btn-soundcloud:hover,
.open > .dropdown-toggle.btn-soundcloud {
	color: #fff;
	background-color: #c40;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud.active.focus,
.btn-soundcloud.active:focus,
.btn-soundcloud.active:hover,
.btn-soundcloud:active.focus,
.btn-soundcloud:active:focus,
.btn-soundcloud:active:hover,
.open > .dropdown-toggle.btn-soundcloud.focus,
.open > .dropdown-toggle.btn-soundcloud:focus,
.open > .dropdown-toggle.btn-soundcloud:hover {
	color: #fff;
	background-color: #a83800;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud.disabled,
.btn-soundcloud.disabled.active,
.btn-soundcloud.disabled.focus,
.btn-soundcloud.disabled:active,
.btn-soundcloud.disabled:focus,
.btn-soundcloud.disabled:hover,
.btn-soundcloud[disabled],
.btn-soundcloud[disabled].active,
.btn-soundcloud[disabled].focus,
.btn-soundcloud[disabled]:active,
.btn-soundcloud[disabled]:focus,
.btn-soundcloud[disabled]:hover,
fieldset[disabled] .btn-soundcloud,
fieldset[disabled] .btn-soundcloud.active,
fieldset[disabled] .btn-soundcloud.focus,
fieldset[disabled] .btn-soundcloud:active,
fieldset[disabled] .btn-soundcloud:focus,
fieldset[disabled] .btn-soundcloud:hover {
	background-color: #f50;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud .badge {
	color: #f50;
	background-color: #fff;
}

.btn-tumblr {
	color: #fff;
	background-color: #2c4762;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr.active,
.btn-tumblr.focus,
.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover,
.open > .dropdown-toggle.btn-tumblr {
	color: #fff;
	background-color: #1c2d3f;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr.active.focus,
.btn-tumblr.active:focus,
.btn-tumblr.active:hover,
.btn-tumblr:active.focus,
.btn-tumblr:active:focus,
.btn-tumblr:active:hover,
.open > .dropdown-toggle.btn-tumblr.focus,
.open > .dropdown-toggle.btn-tumblr:focus,
.open > .dropdown-toggle.btn-tumblr:hover {
	color: #fff;
	background-color: #111c26;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr.disabled,
.btn-tumblr.disabled.active,
.btn-tumblr.disabled.focus,
.btn-tumblr.disabled:active,
.btn-tumblr.disabled:focus,
.btn-tumblr.disabled:hover,
.btn-tumblr[disabled],
.btn-tumblr[disabled].active,
.btn-tumblr[disabled].focus,
.btn-tumblr[disabled]:active,
.btn-tumblr[disabled]:focus,
.btn-tumblr[disabled]:hover,
fieldset[disabled] .btn-tumblr,
fieldset[disabled] .btn-tumblr.active,
fieldset[disabled] .btn-tumblr.focus,
fieldset[disabled] .btn-tumblr:active,
fieldset[disabled] .btn-tumblr:focus,
fieldset[disabled] .btn-tumblr:hover {
	background-color: #2c4762;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr .badge {
	color: #2c4762;
	background-color: #fff;
}

.btn-twitter {
	color: #fff;
	background-color: #55acee;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter.active,
.btn-twitter.focus,
.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover,
.open > .dropdown-toggle.btn-twitter {
	color: #fff;
	background-color: #2795e9;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter.active.focus,
.btn-twitter.active:focus,
.btn-twitter.active:hover,
.btn-twitter:active.focus,
.btn-twitter:active:focus,
.btn-twitter:active:hover,
.open > .dropdown-toggle.btn-twitter.focus,
.open > .dropdown-toggle.btn-twitter:focus,
.open > .dropdown-toggle.btn-twitter:hover {
	color: #fff;
	background-color: #1583d7;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter.disabled,
.btn-twitter.disabled.active,
.btn-twitter.disabled.focus,
.btn-twitter.disabled:active,
.btn-twitter.disabled:focus,
.btn-twitter.disabled:hover,
.btn-twitter[disabled],
.btn-twitter[disabled].active,
.btn-twitter[disabled].focus,
.btn-twitter[disabled]:active,
.btn-twitter[disabled]:focus,
.btn-twitter[disabled]:hover,
fieldset[disabled] .btn-twitter,
fieldset[disabled] .btn-twitter.active,
fieldset[disabled] .btn-twitter.focus,
fieldset[disabled] .btn-twitter:active,
fieldset[disabled] .btn-twitter:focus,
fieldset[disabled] .btn-twitter:hover {
	background-color: #55acee;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter .badge {
	color: #55acee;
	background-color: #fff;
}

.btn-vimeo {
	color: #fff;
	background-color: #1ab7ea;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo.active,
.btn-vimeo.focus,
.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover,
.open > .dropdown-toggle.btn-vimeo {
	color: #fff;
	background-color: #1295bf;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo.active.focus,
.btn-vimeo.active:focus,
.btn-vimeo.active:hover,
.btn-vimeo:active.focus,
.btn-vimeo:active:focus,
.btn-vimeo:active:hover,
.open > .dropdown-toggle.btn-vimeo.focus,
.open > .dropdown-toggle.btn-vimeo:focus,
.open > .dropdown-toggle.btn-vimeo:hover {
	color: #fff;
	background-color: #0f7b9f;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo.disabled,
.btn-vimeo.disabled.active,
.btn-vimeo.disabled.focus,
.btn-vimeo.disabled:active,
.btn-vimeo.disabled:focus,
.btn-vimeo.disabled:hover,
.btn-vimeo[disabled],
.btn-vimeo[disabled].active,
.btn-vimeo[disabled].focus,
.btn-vimeo[disabled]:active,
.btn-vimeo[disabled]:focus,
.btn-vimeo[disabled]:hover,
fieldset[disabled] .btn-vimeo,
fieldset[disabled] .btn-vimeo.active,
fieldset[disabled] .btn-vimeo.focus,
fieldset[disabled] .btn-vimeo:active,
fieldset[disabled] .btn-vimeo:focus,
fieldset[disabled] .btn-vimeo:hover {
	background-color: #1ab7ea;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo .badge {
	color: #1ab7ea;
	background-color: #fff;
}

.btn-vk {
	color: #fff;
	background-color: #587ea3;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk.active,
.btn-vk.focus,
.btn-vk:active,
.btn-vk:focus,
.btn-vk:hover,
.open > .dropdown-toggle.btn-vk {
	color: #fff;
	background-color: #466482;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk.active.focus,
.btn-vk.active:focus,
.btn-vk.active:hover,
.btn-vk:active.focus,
.btn-vk:active:focus,
.btn-vk:active:hover,
.open > .dropdown-toggle.btn-vk.focus,
.open > .dropdown-toggle.btn-vk:focus,
.open > .dropdown-toggle.btn-vk:hover {
	color: #fff;
	background-color: #3a526b;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk.disabled,
.btn-vk.disabled.active,
.btn-vk.disabled.focus,
.btn-vk.disabled:active,
.btn-vk.disabled:focus,
.btn-vk.disabled:hover,
.btn-vk[disabled],
.btn-vk[disabled].active,
.btn-vk[disabled].focus,
.btn-vk[disabled]:active,
.btn-vk[disabled]:focus,
.btn-vk[disabled]:hover,
fieldset[disabled] .btn-vk,
fieldset[disabled] .btn-vk.active,
fieldset[disabled] .btn-vk.focus,
fieldset[disabled] .btn-vk:active,
fieldset[disabled] .btn-vk:focus,
fieldset[disabled] .btn-vk:hover {
	background-color: #587ea3;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk .badge {
	color: #587ea3;
	background-color: #fff;
}

.btn-yahoo {
	color: #fff;
	background-color: #720e9e;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo.active,
.btn-yahoo.focus,
.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover,
.open > .dropdown-toggle.btn-yahoo {
	color: #fff;
	background-color: #500a6f;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo.active.focus,
.btn-yahoo.active:focus,
.btn-yahoo.active:hover,
.btn-yahoo:active.focus,
.btn-yahoo:active:focus,
.btn-yahoo:active:hover,
.open > .dropdown-toggle.btn-yahoo.focus,
.open > .dropdown-toggle.btn-yahoo:focus,
.open > .dropdown-toggle.btn-yahoo:hover {
	color: #fff;
	background-color: #39074e;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo.disabled,
.btn-yahoo.disabled.active,
.btn-yahoo.disabled.focus,
.btn-yahoo.disabled:active,
.btn-yahoo.disabled:focus,
.btn-yahoo.disabled:hover,
.btn-yahoo[disabled],
.btn-yahoo[disabled].active,
.btn-yahoo[disabled].focus,
.btn-yahoo[disabled]:active,
.btn-yahoo[disabled]:focus,
.btn-yahoo[disabled]:hover,
fieldset[disabled] .btn-yahoo,
fieldset[disabled] .btn-yahoo.active,
fieldset[disabled] .btn-yahoo.focus,
fieldset[disabled] .btn-yahoo:active,
fieldset[disabled] .btn-yahoo:focus,
fieldset[disabled] .btn-yahoo:hover {
	background-color: #720e9e;
	border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo .badge {
	color: #720e9e;
	background-color: #fff;
}
