.login .close:focus,
.register_top .close:focus,
.signin_username .btn-started:focus {
    outline: 0
}

.login .modal_close span,
.login .signinsignup-widget .nav-tabs>li>a,
.popup-title h1,
.register_top .modal_close span,
.signin-signup {
    font-family: Roboto, sans-serif
}

.login-right,
.register-left {
    padding: 0 12px
}

.login .modal-dialog,
.register_top .modal-dialog {
    margin: 140px auto
}

.login .modal-header {
    padding: 22px 20px;
    background-color: #e12214;
    font-size: 14px;
    text-align: center;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative
}

.login .close,
.register_top .close {
    float: none;
    font-size: 28px;
    font-weight: 500;
    text-shadow: none;
    filter: alpha(opacity=100);
    opacity: 1;
    margin-top: 0;
    position: absolute;
    right: 14px;
    top: 11px;
    color: #001563;
    line-height: 1
}

.login .modal-body {
    zoom: 1;
    background-color: #f2f2f2
}

.social-loginlink {
    padding: 2px 0
}

.login-form {
    margin: 0 auto
}

.social-loginlink .heading {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    text-align: center;
    padding-bottom: 8px
}

.login .tab-content {
    padding: 5px 0 0
}

.login .signinsignup-widget,
.register_top .signinsignup-widget {
    padding: 15px 15px 16px
}

.login .signinsignup-widget .nav-tabs>li.active>a {
    color: #001563;
    cursor: auto
}

.login .signinsignup-widget .nav-tabs>li>a {
    color: #000508;
    cursor: pointer;
    font-weight: 500
}

.login .signinsignup-widget .nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: 1px solid #d6dce7;
    border-bottom-color: transparent
}

.login .signinsignup-widget .nav-tabs {
    border-bottom: 1px solid #d6dce7
}

.login .modal_close,
.register_top .modal_close {
    padding: 18px 40px 15px 30px;
    position: relative;
    min-height: 20px;
    text-align: center;
    border-radius: 4px 4px 0 0;
    color: #001563;
    background-color: #fbfbfb;
    box-shadow: 0 0 2px #e7e7e7;
    font-weight: 400
}

.login .alert-error,
.register_top .alert-error {
    background-color: #a30004;
    color: #fff;
    font-size: 12px;
    padding: 2px 6px;
    display: block;
    margin: 8px 0 0
}

.login .modal_close span {
    font-weight: 500;
    font-size: 16px
}

.popup-title {
    padding-bottom: 8px
}

.popup-title h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    border-bottom: none;
    text-transform: capitalize;
    color: #363636
}

.storytitle {
    margin: 2px auto;
    width: 90%
}

.storytitle h1 {
    position: relative;
    color: #39341e;
    font-size: 13px;
    font-weight: 400;
    text-transform: lowercase;
    background: 0 0;
    border: none;
    margin: 0 0 10px
}

.storytitle h1.one {
    text-align: center
}

.storytitle h1.one:before {
    content: "";
    display: block;
    border-top: solid 1px #e2e7db;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 1
}

.storytitle h1.one span {
    background: #fff;
    padding: 0 15px;
    position: relative;
    z-index: 5
}

.social-loginlink .heading .fa {
    margin-right: 4px;
    font-size: 16px
}

.social-loginlink .social-link {
    padding: 0
}

.social-loginlink .social-link .social-login {
    padding: 4px 0 0
}

.social-loginlink .social-link .social-login .login-gplus {
    padding: 0 0 10px;
    width: 48%;
    float: right
}

.social-loginlink .social-link .social-login .login-facebook {
    padding: 0 0 10px;
    width: 48%;
    float: left
}

.social-loginlink .social-link .social-login a {
    font-size: 13px;
    text-align: center;
    padding: 8px 8px 8px 30px;
    font-weight: 600
}

.social-loginlink .social-link .btn-social>:first-child {
    top: 2px;
    width: 36px;
    font-size: 1.4em;
    text-align: center
}

.login .divider {
    position: relative;
    border-bottom: 1px solid rgba(51, 51, 51, .17)
}

.login .divider .or {
    background-color: #F2F2F2;
    border-radius: 50%;
    color: #666;
    font-size: 12px;
    position: absolute;
    top: -15px;
    width: 30px;
    height: 30px;
    padding-top: 6px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #ccc;
    text-align: center
}

.signin_username {
    padding: 12px 0 10px;
    margin: 0 auto
}

.signin_username .mck-form-group {
    margin-bottom: 16px;
    position: relative
}

.signin_username .input-group {
    background-color: #fff;
    padding: 2px;
    border-radius: 2px;
    border: 1px solid #d6dce7
}

.signin_username .input-group-addon:first-child {
    border-right: 0
}

.signin_username .input-group-addon {
    background-color: #fff;
    border: none;
    padding: 0 12px;
    min-width: 46px
}

.signin_username .mck-form-group .form-control {
    border: none;
    border-left: 1px solid #d6dce7;
    box-shadow: none;
    padding: 0 12px;
    font-size: 14px
}

.signin_username .getstarted {
    padding: 0
}

.login .seprator {
    border-left: 1px dotted #777;
    min-height: 300px
}

.login .tooltip-top-left:after,
.login .tooltip-top-right:after {
    top: 100%;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.signin_username .btn-started {
    background-color: #001563;
    color: #fff;
    width: 100%;
    border-bottom: 2px solid #001563;
    padding: 10px 20px;
    font-size: 15px;
    line-height: 1;
    font-weight: 600;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    border-radius: 2px
}

.signin_username .btn-started:hover {
    background-color: #f23209;
    color: #fff
}

.login .forgot-password {
    padding: 16px 0 0;
    font-size: 13px;
    text-align: center;
    margin: 0
}

.login .forgot-password a {
    color: #2f63cf;
    display: inline-block;
    padding-bottom: 12px;
    margin: 0
}

.login .modal-dialog {
    width: 700px
}

.register_top .modal-dialog {
    width: 320px
}

.signup-form {
    padding-bottom: 14px
}

.login .redirect-bottom {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #666;
    font-size: 12px
}

.login .redirect-bottom a {
    color: #c02c39;
    text-decoration: underline;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer
}

.divider-msg {
    text-align: center;
    padding-top: 6px
}

.signin-signup {
    float: right
}

.signin-signup ul {
    margin: 0;
    padding: 17px 0 2px
}

.signin-signup ul li {
    float: left;
    margin-left: 15px
}

.signin-signup ul li a {
    color: #4e4e4e;
    background-color: #fff;
    font-weight: 400;
    padding: 6px 8px;
    display: inline-block;
    border-radius: 2px;
    font-size: 14px;
    line-height: 1;
    text-decoration: none;
    box-shadow: 0 1px 1px #830016
}

.signin-signup ul li a .fa {
    margin-right: 2px
}

.login .tooltip {
    position: absolute;
    z-index: 5;
    left: -9999px;
    padding: 2px 8px 3px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    background: #0da7db;
    color: #fff;
    opacity: 0;
    transition: margin .3s, opacity .3s;
    -o-transition: margin .3s, opacity .3s;
    -ms-transition: margin .3s, opacity .3s;
    -moz-transition: margin .3s, opacity .3s;
    -webkit-transition: margin .3s, opacity .3s
}

.login .tooltip:after {
    content: '';
    position: absolute
}

.login .textarea textarea:focus+.tooltip,
.register-form .input input:focus+.tooltip {
    opacity: 1
}

.login .tooltip-top-right {
    bottom: 100%;
    margin-bottom: 15px
}

.login .tooltip-top-right:after {
    right: 16px;
    border-top: 4px solid #0da7db
}

.login .input input:focus+.tooltip-top-right,
.login .textarea textarea:focus+.tooltip-top-right {
    right: 0;
    left: auto;
    margin-bottom: 5px
}

.login .tooltip-top-left {
    bottom: 100%;
    margin-bottom: 15px
}

.login .tooltip-top-left:after {
    left: 16px;
    border-top: 4px solid #0da7db)
}

.login .tooltip-left:after,
.login .tooltip-right:after {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent
}

.login .input input:focus+.tooltip-top-left,
.login .textarea textarea:focus+.tooltip-top-left {
    right: auto;
    left: 0;
    margin-bottom: 5px
}

.login .tooltip-right {
    top: 9px;
    white-space: nowrap;
    margin-left: 15px
}

.login .tooltip-right:after {
    top: 6px;
    right: 100%;
    border-right: 4px solid #0da7db
}

.login .tooltip-left {
    top: 9px;
    white-space: nowrap;
    margin-right: 15px
}

.login .tooltip-left:after {
    top: 6px;
    left: 100%;
    border-left: 4px solid #0da7db)
}

.login .tooltip-bottom-left:after,
.login .tooltip-bottom-right:after {
    bottom: 100%;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #0da7db;
    border-left: 4px solid transparent
}

.login .input-group input:focus+.tooltip-left,
.login .textarea textarea:focus+.tooltip-left {
    right: 100%;
    left: auto;
    margin-right: 5px
}

.login .tooltip-bottom-right {
    top: 100%;
    margin-top: 12px
}

.login .tooltip-bottom-right:after {
    right: 16px
}

.login .tooltip-bottom-left {
    top: 100%;
    margin-top: 15px
}

.login .tooltip-bottom-left:after {
    left: 16px
}

.login .input input:focus+.tooltip-bottom-left,
.login .textarea textarea:focus+.tooltip-bottom-left {
    right: auto;
    left: 0;
    margin-top: 5px
}

.login .input input:focus+.tooltip-right,
.login .textarea textarea:focus+.tooltip-right {
    left: 100%;
    margin-left: 5px
}

.login .mck-form-group .input-group input:focus+.tooltip,
.login .textarea textarea:focus+.tooltip {
    opacity: 1;
    left: 1px
}
@media (min-width:900px) {
    .tambola-links-row {
        width: 500px;
        padding-bottom: 22px;
    }
}
@media (max-width:768px) {
    .login .modal-dialog {
        width: 600px
    }
    .tgame-no {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1;
        padding: 2px 0 0;
    }
    .tambola-info {
        width: 75%;
        margin: 0 auto;
    }
    .game-startat {
        font-size: 37px;
        /* color: #a50718; */
        color: #0e7ea4;
        line-height: 1;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        text-align: center;
        /* padding-left: 40px; */
    }
    .timer-bg {
        /* background: url(/static/media/timer-background.a0b01f2c.png) center no-repeat; */
        padding: 58px 20px;
        background-color: #001563;
        border-radius: 100px;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        /* min-width: 212px; */
        /* min-height: 212px; */
        height: 200px;
        width: 200px;
        background-color: #001563;
        border-radius: 50%;
        display: inline-block;
    }
    .buy-ticket .modal-dialog {
        width: 100%;
        margin: 150px auto;
        /* padding: 4px; */
    }
    .tkt-no-round {
        padding: 8px 0;
    }
    .tambola-bottom {
		padding: 5px 10px;
	}
	.tkt-no {
		margin: 0 -5px 0 0;
	}
	.tambola-bottom {
		padding: 5px 10px;
	}
	.tkt-no-bg {
		padding: 15px;
		font-size: 20px;
		line-height: 1;
		width: 60px;
    height: 60px;
		text-align: center;
		background-size: contain;
		background-position: center;
    }
    .no-duration {
        font-size: 10px;
    }
    .icon-clock {
        display: inline-block;
        margin: 2px 7px 0 -2px;
        float: left;
        width: 10px;
    }
    .icon-clock img {
        width: 14px;
        height: auto;
    }
    .no-duration .text-duration {
        font-size: 16px;
    }
    .number-board {
        margin-left: -18px;
    }
    .number-board .board-title {
        font-size: 14px;
        padding: 0 0 4px;
    }
    .number-table ul li {
        float: left;
        padding: 3px;
        width: 10%;
        margin: 0;
        color: #565656;
        font-size: 10px;
        border-left: 1px solid #A7A7A7;
        border-bottom: 1px solid #A7A7A7;
        min-height: 22px;
        line-height: 1;
    }
    .ticket-box-main {
        padding: 0 0 25px;
    }
    .tk-boxleft .titlebar {
        padding: 0 0 8px;
        font-size: 13px;
    }
    .tk-boxlefttick1 .titlebar {
        padding: 0 0 8px;
        font-size: 13px;
    }
    .tkticon {
        margin: 5px 6px 0 0;
    }
    .tkticon img {
        width: 13px;
        margin-top: -20px;
    }
    .ticket-table {
        font-size: 16px;
    }
    .claim-now-main-inner {
        width: 100%;
        margin: 0 auto;
        padding: 10px 5px;
    }
    .claim-nowlink {
        width: 90%;
        margin: 15px auto;
    }
    .claim-nowleft .btn-claim, .claim-nowright .btn-claim {
        font-size: 16px;
        padding: 14px;
        margin-bottom: 5px;
    }
    .claim-nowleft .btn-claim2, .claim-nowright .btn-claim2 {
        font-size: 16px;
        padding: 14px;
        margin-bottom: 5px;
    }
    .tambola-details {
        padding: 28px 0 0;
    }
    .rules {
        padding: 0 0 15px;
    }
    .quiz-rules-title h1, .quiz-winners-title h1, .rules-title h1, .wc-title h1, .winners-title h1 {
        font-size: 15px;
    }
    .rules-list {
        padding: 10px 0 0;
    }
    .quiz-rules-list ul li, .rules-list ul li {
        padding-bottom: 8px;
        font-size: 13px;
        line-height: 1.4;
    }
    .quiz-rules-list ul li .fa, .rules-list ul li .fa {
        display: none;
    }
    .ticket2{
        margin-top: 10px;
    }
}

@media (max-width:480px) {
    .login .modal-dialog {
        width: 94%
    }
    .loginmob{
        display:none;
    }
    .tambola-playbox .title {
        padding: 7px 9px 5px;
    }
    .title-row .gamenum {
        float: left;
        width: 50%;
    }
    .tgame-no {
        text-align: left;
        font-size: 10px;
        padding: 4px 0 0;
    }
    .title-row .date-time {
        float: right;
        width: 50%;
    }
    .tambola-start .game-details {
        float: none;
        width: 100%;
        text-align: left;
        font-size: 11px;
        padding: 3px 0 0;
    }
    .tambola-start .game-details .take-left {
        float: left;
        margin-right: 5px;
    }
    .game-details .fa {
        margin-right: 7px;
    }
    .current-date {
        float: none;
    }
    .tambola-start .icon-t {
        margin: -1px 9px 0 0;
        float: left;
    }
    .tambola-start .icon-t img {
        width: 13px;
        height: 13px;
    }
    .current-time {
        float: right;
    }
    .tambola-start-bottom {
        padding: 11px 6px 7px;
        background-color: #FFF;
    }
    .tambola-info {
        width: 96%;
        margin: 0 auto;
    }
    .tambola-info h1 {
        padding-bottom: 13px;
        font-size: 16px;
        text-align: center;
    }
    .game-startat {
        font-size: 19px;
        padding-left: 23px;
    }
    .tambola-timer {
        padding: 10px 0 18px;
    }
    .timer-bg {
        padding: 32px 19px;
        background-size: 100%;
        min-width: 104px;
        min-height: inherit;
    }
    .timer-bg .heading_join {
        font-size: 20px;
        padding-bottom: 10px;
        font-family: Roboto,sans-serif;
    }
    .timer-bg {
        padding: 40px 19px;
        background-size: 100%;
        /* min-width: 104px;
        min-height: inherit; */
        width:140px;
        height: 140px;
    }
    .timer-bg .time-interval {
        font-size: 16px;
        padding-bottom: 5px;
    }
    .tambola-links {
        padding: 0 1px;
    }
    .tambola-colleft a, .tambola-colright a {
        font-size: 13px;
        padding: 7px 5px 6px 9px;
    }
    
    .link-singleicon {
        padding-top: 3px;
    }
    .link-icon {
        width: 18%;
    }
    .link-icon img {
        width: 100%;
        height: auto;
    }
    .link-singletext {
        padding-top: 7px !important;
        padding-bottom: 9px !important;
    }
    .link-text {
        width: 75%;
        padding: 0 0 0 7px;
        text-align: center;
        line-height: 1.3;
    }
    .tambola-colleft a, .tambola-colright a {
        font-size: 13px;
        padding: 7px 5px 6px 9px;
    }
   
    .login .seprator {
        border-left: none;
        padding-top: 15px
    }
    .login .modal-dialog .modal-content .signinsignup-widget {
        width: 100%;
        margin: 0 auto;
        padding: 12px 0 16px
    }
    .signin-signup ul li {
        float: left;
        margin-left: 2px
    }
    .signin-signup ul {
        padding: 17px 9px 2px 0
    }
    .signin-signup ul li a {
        /* background-color: transparent;
        font-weight: 400;
        padding: 4px 1px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 1.2;
        text-decoration: none;
        box-shadow: none;
        color: #fff */
        background-color: #fff;
    font-weight: 400;
    padding: 6px 8px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1.2;
    text-decoration: none;
    box-shadow: none;
    color: #4e4e4e;
    }
    .tkt-no-bg {
		padding: 20px 14px;
    }
    .number-table ul li {
        line-height: 1.4;
        text-align: center;
    }
    .ticket-table {
        font-size: 16px;
        font-weight: 500;
    }
    .tk-boxleft .table, .tk-boxright .table {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-webkit-box-shadow: none;
    }
    .tk-boxlefttick1 .table, .tk-boxright .table {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-webkit-box-shadow: none;
    }
  
.ticket-common {
    padding-bottom: 15px;
}
.ticket-common a {
    padding: 32px;
    font-size: 22px;
    background-size: 100%;
} 
.wh-250 {
    margin-left: -20px;
    width: 180px;
}
.custcarousel {
	display: block;
	text-align: start;
	float: none;
	position: relative;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	width: 972px;
	height: 180px;
	margin: 0px;
	overflow: hidden;
}

.list_carousel .cust {
	padding: 0;
	display: block;
	float: none;
}
.list_carousel .row{
    display: flex;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
}
.list_carousel ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: none;
}
.howtoplaygame .modal-body .innerbottom .heading {
    text-align: center;
    font-size: 15px;
}
.howtoplaygame .modal-body .innerbottom .content ul li span.points {
    font-weight: 500;
    font-size: 14px;
}
.howtoplaygame .modal-body .innerbottom .content ul li span.points {
    width: 30%;
}
.howtoplaygame .modal-body .innerbottom .content ul li span.points_txt {
    padding: 0 0 0 15px;
    width: 70%;
    font-size: 13px;
    font-weight: 500;
}
.last-gamewinners {
    font-size: 11px;
}
.last-gamewinners .tambola-winners-list th, .tambola-result .tambola-winners-list td {
    padding: 6px;
}
.winners-pic {
    margin: 0 10px 0 0;
}
.winners-pic img {
    width: 25px;
    height: 26px;
}
span.text_singleline {
    float: none;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.winners-name {
    margin: 0;
}
.vertical-center {
    margin: 0;
    position: absolute;
    top: 70%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
}
.subtitletext {
    font-size: 22px;
    margin-top: 20px;
    color: #001563;
}
.funds .signin_username .input-group{
    padding:2px;
    margin-top: 15px;
}
.funds .claim-nowleft{
    margin-top: 10px !important;
}
.resume .btn{
    
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 4px;
        font-size: 10px;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
}
.register-box {
	float: left;
	width: 100%;
}
.registerbox-inner {
    padding: 0px;
}
.register-form{
    padding:0px
}
.fr{float:none !important}
.claim-nowleft .btn-claim3,
.claim-nowright .btn-claim3 {
    padding:10px  !important;
    margin-top: 10px;
}
.claim-nowleft .btn-claim,
.claim-nowright .btn-claim {
    margin-top: 10px;
    padding:10px !important;
}
.trth{
    width:auto;
}
.signin-signup {
    float: right;
    padding: 0px;
    margin-top: -4px;
}
.mtop {
    margin-top: 0px;
}
.winner-detail{
    margin-left:-5px
}
.font10{
    font-size: 10px !important;
}
}
