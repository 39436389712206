.mb-logo {
	float: left;
	padding: 12px 0 10px;
}

.mb-logo a img {
	outline: 0;
	border: none;
}

.mb-right {
	float: right;
	margin-right: 15px;
}

.mb-right ul {
	padding-top: 0;
	position: relative;
}

.mb-right .notifications-menu > .dropdown-menu > li .menu > li > a {
	color: #777;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 10px;
	text-decoration: none;
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid #f4f4f4;
	font-size: 12px;
}

.mb-right .dropdown-menu .fa {
	width: 20px;
	color: #f12041;
	margin-right: 2px;
}

.mb-right .notifications-menu {
	padding-top: 16px;
	float: left;
}

.mb-right .user-menu {
	float: left;
	margin-left: 31px;
	padding-top: 13px;
}

.mb-right .dropdown-menu > li > a {
	text-decoration: none;
	font-size: 12px;
	padding: 5px 11px;
}

.mb-right .notifications-menu > .dropdown-menu > li.header {
	border-radius: 4px 4px 0 0;
	background-color: #fff;
	padding: 5px 11px;
	border-bottom: 1px solid #f4f4f4;
	color: #444;
	font-size: 12px;
}

.points_earned {
	font-size: 12px;
	text-align: center;
	display: block;
	padding: 0 10px;
}

.mb-right .dropdown-menu {
	position: absolute;
	top: 51px;
	left: -165px;
	z-index: 1000;
	float: left;
	width: 190px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
}

.menu-btn,
.site-footer,
.site-header {
	text-align: center;
}

.mb-right .totalnotification {
	position: absolute;
	width: 16px;
	height: 14px;
	right: -15px;
	top: 12px;
	background-color: #0dc30e;
	color: #fff;
	font-weight: 700;
	font-size: 11px;
	line-height: 1;
	padding: 1px 0;
	border-radius: 1px;
	text-align: center;
}

.mb-right .notifications-menu .dropdown-toggle {
	padding: 0;
}

#menu-button {
	background: url(../images/responsive-btn.png) center center no-repeat;
	width: 34px;
	height: 51px;
	position: absolute;
	top: 1px;
	left: 13px;
}

#container {
	position: relative;
}

.menu-btn {
	padding: 14px 0 0;
	cursor: pointer;
	float: left;
	width: 58px;
}

.menu-btn:hover {
	opacity: 0.6;
}

.site-header {
	position: fixed;
	background: #000;
	color: #fff;
	width: 100%;
	padding: 20px 0;
	z-index: 9998;
}

.site-footer {
	position: relative;
	border-top: 1px solid #eee;
	color: #999;
	padding: 10px;
	font-size: 0.8em;
}
