.btn-facebook {
  color: #fff !important;
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-google {
  color: #fff !important;
  background-color: #dd4b39;
  border-color: rgba(0, 0, 0, 0.2);
}
.styles_modal__gNwvD {
  max-width: 800px;
  position: initial;
  padding: 1.2rem;
  background: transparent;
  box-shadow: none;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-dialog {
  max-width: 700px !important;
}
.navbar-nav > li {
  float: left !important;
}
.navbar-nav {
  flex-direction: row;
}
.dropdown-menu {
  display: block !important;
}
.justifycenter {
  justify-content: center;
}
.signin-row .right-squareadd {
  float: right;
  width: 28%;
  overflow: hidden;
  padding: 65px 0 0 0;
}
.register-form {
  /* background: url(../images/register-boxbg.jpg) repeat-y; */
  background-color: #001563;
  background-size: 100%;
  padding: 25px 28px;
  border-radius: 2px;
  border: 1px solid #001563;
}
.forgot-password {
  padding: 50px 40px;
}
.fgt-formbottom {
  padding: 30px 0 0 0;
}
#mid-section {
  margin: 0;
  padding: 0;
}
.register-main {
  margin: 0;
  padding: 30px 0 10px 0;
}
.forgot-password .forgotpass-tips {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  line-height: 1.3;
}
.forgot-password .forgotpass-tips p {
  font-size: 13px;
}
.forgot-password h1 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  padding-bottom: 4px;
}
.signin-row {
  margin: 0;
}
.clearfix {
  zoom: 1;
}
.signin-row .left-squareadd {
  float: left;
  width: 28%;
  overflow: hidden;
  padding: 65px 0 0 0;
}
.register-box {
  float: left;
  width: 44%;
}
.registerbox-inner {
  padding: 0 18px 0 18px;
}
.register-form .btn-started {
  background-color: white;
  color: #001563;
  width: 100%;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 1;
  font-weight: 600;
  outline: 0;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
.signin-row .right-squareadd {
  float: right;
  width: 28%;
  overflow: hidden;
  padding: 65px 0 0 0;
}
.capitalize {
  text-transform: capitalize;
}
#play-tambola {
  padding: 15px 0 0;
}
.tambola-playbox {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.tambola-playbox .title {
  padding: 15px 15px 15px 25px;
  background-color: #0da7db;
  /* background-color: #a50718; */
  border-radius: 5px 5px 0 0;
  color: #fff;
  line-height: 1;
}
.tambola-start {
  margin: 0;
}
.title-row {
  margin: 0;
}
.title-row .gamenum {
  float: left;
  width: 30%;
}
.tgame-no {
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1;
  padding: 2px 0 0;
}
.no-duration,
.tgame-no {
  font-weight: 400;
  font-family: Roboto, sans-serif;
}
.title-row .date-time {
  float: right;
  width: 70%;
}
.tambola-start .game-details {
  float: right;
  text-align: right;
  padding-right: 12px;
}
.game-details {
  font-size: 15px;
  line-height: 1;
  font-family: Roboto, sans-serif;
  padding: 3px 0 0 20px;
}
.tambola-start .game-details .take-left {
  float: left;
  margin-right: 50px;
}
.current-date,
.current-time,
.tambola-start .game-details .take-right {
  float: right;
}
.current-date,
.current-time,
.tambola-start .game-details .take-right {
  float: right;
}
.tambola-start .icon-t {
  display: inline-block;
  margin: -1px 9px 0 0;
  float: left;
}
a,
area,
img,
link,
menu,
nav {
  border: none;
}
.current-date,
.current-time,
.tambola-start .game-details .take-right {
  float: right;
}
.tambola-start-bottom {
  padding: 18px 50px 25px;
}
.tambola-info {
  width: 45%;
  margin: 0 auto;
}
.tambola-info h1 {
  color: #424242;
  padding-bottom: 24px;
  font-size: 26px;
}
.game-offer .inner,
.tambola-info h1 {
  font-weight: 500;
  font-family: Roboto, sans-serif;
  text-align: center;
}
.game-startat {
  font-size: 47px;
  /* color: #a50718; */
  color: #0e7ea4;
  line-height: 1;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  text-align: center;
}
.time {
  font-size: 50px;
  position: relative;
}

.clock-icon,
.time {
  display: inline-block;
}
.tambola-timer {
  padding: 20px 0 40px;
  text-align: center;
}
.mega-dropdown-menu > li ul > li > a:focus,
.mega-dropdown-menu > li ul > li > a:hover,
.movie-details .movie-name a,
a,
a:focus,
a:visited {
  text-decoration: none !important;

  cursor: pointer;
}
.timer-bg {
  /* background: url(../images/timer-background.png) center no-repeat;
	padding: 60px 62px;
	text-align: center;
	display: inline-block;
	margin: 0 auto;
	min-width: 212px;
	min-height: 212px; */
}
.timer-bg {
  /* background: url(/static/media/timer-background.a0b01f2c.png) center no-repeat; */
  padding: 80px 40px;
  background-color: #001563;
  border-radius: 100px;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  /* min-width: 212px; */
  /* min-height: 212px; */
  height: 250px;
  width: 250px;
  background-color: #001563;
  border-radius: 50%;
  display: inline-block;
}

.timer-bg .heading_join {
  font-size: 35px;
  padding-top: 10px;
}
.timer-bg .heading,
.timer-bg .heading_join {
  padding-bottom: 21px;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  font-family: Roboto, sans-serif;
}
.timer-bg .time-interval {
  color: #fff;
  font-size: 25px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  padding-bottom: 5px;
  line-height: 1;
}
.timer-bg .time-interval {
  color: #fff;
  font-size: 25px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  padding-bottom: 5px;
  line-height: 1;
}
.tambola-links {
  padding: 0 10px;
}

.tambola-colright {
  width: 45%;
  float: right;
}

.tambola-links-row {
  padding-bottom: 22px;
}
.tambola-colleft {
  width: 45%;
  float: left;
}
.tambola-colleft:hover {
  /* margin-bottom: 20px; */
  display: inline-block !important;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.tambola-colleft a,
.tambola-colright a {
  display: block;
  border: 1px solid #001563;
  border-radius: 3px !important;
  color: #001563 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  padding: 13px 5px 13px 9px !important;
  line-height: 1;
  vertical-align: middle;
}
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.link-icon {
  vertical-align: middle;
  width: 21%;
}
.link-icon,
.link-text {
  float: left;
  display: block;
}
.link-text {
  width: 75%;
  padding: 0 0 0 7px;
}
.link-singletext {
  padding-top: 10px;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.buy-ticket {
  margin: 0;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.buy-ticket .modal-dialog {
  width: 32%;
  margin: 150px auto;
}
.buy-ticket .modal-content {
  border: 1px solid #0da7db;
}
.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.buy-ticket .modal-header {
  min-height: 16.43px;
  padding: 15px 15px 30px;
  border-bottom: none;
}
.modal-header .close {
  margin-top: -2px;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.ticket-common {
  padding-bottom: 32px;
}
.ticket-common a {
  display: block;
  padding: 35px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.1;
  color: #fff;
  text-shadow: 1px 0 1px #1f8db2;
  text-decoration: none;
  background: url(../images/tambola-ticket.jpg) center no-repeat;
}
.ticket-common a span {
  display: block;
}
.dropdown .username:hover {
  color: white !important;
}
.tambola-bottom {
  padding: 25px 50px;
}
.number-board,
.number-table ul,
.tkt-no {
  margin: 0;
}
.tkt-no-round {
  padding: 8px 0 15px;
}
.tkt-no-bg {
  /* background: url(../images/tkt-no-bg.png) no-repeat; */
  padding: 45px 37px 50px;
  background-color: #001563;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 72px;
  line-height: 1;
  width: 161px;
  height: 161px;
  color: #fff;
  text-align: center;
}
.no-duration {
  color: #404040;
  font-size: 18px;
}
.no-duration,
.tgame-no {
  font-weight: 400;
  font-family: Roboto, sans-serif;
}
.icon-clock {
  margin: 5px 8px 0 0;
  float: left;
}
.icon,
.icon-clock,
.icon-p,
.icon-t {
  display: inline-block;
}
a,
area,
img,
link,
menu,
nav {
  border: none;
}
.no-duration .text-duration {
  color: #001563;
  font-size: 33px;
  line-height: 1;
  font-weight: 700;
}
.number-board,
.number-table ul,
.tkt-no {
  margin: 0;
}
.number-board .board-title {
  text-transform: uppercase;
  color: #5b5b5b;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 0 0 13px;
}
.number-table {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  border-top: 1px solid #a7a7a7;
  border-right: 1px solid #a7a7a7;
}
.number-table ul {
  width: 100%;
  display: block;
  padding: 0;
}
.number-board,
.number-table ul,
.tkt-no {
  margin: 0;
}
.number-table ul li.previous {
  background: #f0f0f0;
  color: #565656;
}
.number-table ul li {
  float: left;
  padding: 8px;
  width: 5%;
  margin: 0;
  color: #565656;
  font-size: 20px;
  border-left: 1px solid #a7a7a7;
  border-bottom: 1px solid #a7a7a7;
  min-height: 38px;
  line-height: 1;
  text-align: center;
}
.top-winners-list-main {
  padding: 30px 0;
}
.top-winners {
  border: 1px solid #e3e3e3;
  background-color: #f7f7f7;
  padding: 8px 30px;
  border-radius: 4px;
}
.tam-topinner {
  width: 100%;
  margin: 0 auto;
}
.list_carousel.responsive {
  width: auto;
  margin-left: 0;
}
.list_carousel ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}
.list_carousel.responsive {
  width: auto;
  margin-left: 0;
}
/* .list_carousel .li {
	padding: 0;
	display: block;
	float: left;
} */
.floatnone {
  float: left;
}
.topmobile {
  display: none;
}
.list_carousel .cust {
  padding: 0;
  display: block;
  float: left;
}
.flex-row {
  flex-direction: row;
}
.winner-detail {
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
}
.winner-detail .user-pic {
  float: left;
  width: 38px;
  height: 38px;
  margin-right: 6px;
}
.user-pic img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.winner-detail h4 {
  font-size: 14px;
}
.winner-detail p {
  font-size: 12px;
}
.winner-detail .user-pic img {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  border: 1px solid #818181;
}
.winner-detail h4 {
  margin: 0;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  padding: 7px 0 1px;
}
.winner-detail h4,
.winner-detail p {
  color: #565656;
  text-align: left;
  line-height: 1;
}
.ticket-box-main {
  padding: 0 0 40px;
}
.ticket-box-main .single-tkt {
  float: none;
  margin: 0 auto;
}
.tk-boxleft {
  margin: 0;
}
.tk-boxlefttick1 {
  margin: 0;
}
.tk-boxleft .titlebar {
  padding: 0 0 18px;
  color: #404040;
}
.tk-boxlefttick1 .titlebar {
  padding: 0 0 18px;
  color: #404040;
}
.tkticon {
  margin: 0px 8px 0 0;
}
.claim-nowleft,
.tkticon {
  float: left;
}
.tk-boxleft .titlebar,
.tk-boxright .titlebar,
.tkticon {
  font-size: 18px;
  font-family: Roboto, sans-serif;
}
.tk-boxlefttick1 .titlebar,
.tk-boxright .titlebar,
.tkticon {
  font-size: 18px;
  font-family: Roboto, sans-serif;
}
.ticket-table {
  font-family: Roboto, sans-serif;
  color: #404040;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.tk-boxleft .table {
  margin: 0;
  border-bottom: 5px solid #001563;
  -webkit-box-shadow: 5px 6px 10px 0 #ccc;
  -moz-box-shadow: 5px 6px 10px 0 #ccc;
  box-shadow: 5px 6px 10px 0 #ccc;
}
.tk-boxlefttick1 .table {
  margin: 0;
  border-bottom: 5px solid #f5540e;
  -webkit-box-shadow: 5px 6px 10px 0 #ccc;
  -moz-box-shadow: 5px 6px 10px 0 #ccc;
  box-shadow: 5px 6px 10px 0 #ccc;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
  display: inline-table !important;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
table,
td,
tr {
  border-collapse: collapse;
  border-spacing: 0;
}
table {
  table-layout: fixed;
}
.ticket-table .table > tbody > tr > td {
  min-width: 39px;
}
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th,
.ticket-table .table-bordered > tbody > tr > td {
  border: 1px solid #bebebe;
}
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.ticket-table .table > tbody > tr > td {
  padding: 8px;
  border-top: 1px solid #bebebe;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.ticket-table a {
  text-decoration: none;
  /* color: #404040; */
}
.not-active {
  pointer-events: none;
  cursor: default;
  background-color: #001563;
  color: white;
}
.not-activetick1 {
  pointer-events: none;
  cursor: default;
  background-color: #f5540e;
  color: white;
}
.claim-now-main {
  padding: 0 0 12px;
}

.claim-nowlink {
  width: 100%;
  margin: 28px auto;
  padding: 10px;
}
.single-claim-tkt {
  float: none !important;
  text-align: center;
}
.claim-nowleft .btn-claim {
  background-color: #f5540e;
  line-height: 1;
}
.claim-nowleft .btn-claim2 {
  background-color: #0da7db;
  line-height: 1;
}
.claim-nowleft .btn-claim3 {
  background-color: green;
  line-height: 1;
  color: white;
  padding: 0.375rem 0.75rem;
}
.claim-nowleft .btn-claim2,
.claim-nowright .btn-claim2 {
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #fff;
  padding: 8px;
}
.claim-nowleft .btn-claim,
.claim-nowright .btn-claim {
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #fff;
  padding: 8px;
}
.tambola-details {
  padding: 40px 0 0;
}
.rules {
  padding: 0 0 25px;
}
.rules-title,
.wc-title,
.winners-title {
  border-bottom: 2px solid #0da7db;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.rules-icon {
  float: left;
  margin: 1px 10px 0 0;
}
.rules-title h1 {
  margin: 0;
}
.rules-title h1,
.wc-title h1,
.winners-title h1 {
  color: #0da7db;
  font-size: 19px;
  line-height: 1;
}
.rules-list {
  padding: 25px 0 0;
  font-family: Roboto, sans-serif;
}
.rules-list ul {
  display: block;
  width: 94%;
  margin: 0 auto;
}
.rules-list ul li {
  display: block;
  padding-bottom: 15px;
  font-size: 15px;
  color: #373737;
  line-height: 1;
}
.rules-list ul li .fa {
  margin: 5px 14px 0 0;
  font-size: 4px;
  color: #373737;
  line-height: 1;
  float: left;
}
.rules-title,
.wc-title,
.winners-title {
  border-bottom: 2px solid #0da7db;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.wc-icon {
  float: left;
  margin: 1px 10px 0 0;
}
.wc-title h1 {
  margin: 0;
}
.rules-title h1,
.wc-title h1,
.winners-title h1 {
  color: #0da7db;
  font-size: 19px;
  line-height: 1;
}
.combination-list {
  padding: 25px 0 0;
  font-family: Roboto, sans-serif;
  color: #565656;
  width: 95%;
  margin: 0 auto;
}
.wc-list {
  display: block;
}
.wc-list li {
  display: block;
  padding-bottom: 8px;
  font-size: 15px;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.carousel .slide {
  background-color: transparent !important;
}
.wh-250 {
  width: 250px;
}
.block {
  display: block;
  float: none !important;
}
.custcarousel {
  display: block;
  text-align: start;
  float: none;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 972px;
  height: 38px;
  margin: 0px;
  overflow: hidden;
}
.howtoplaygame {
  font-family: Roboto, sans-serif;
  color: #fff;
}
.howtoplaygame .modal-header {
  background-color: #0da7db;
  border: none;
  padding: 12px 15px 12px 18px;
}

.howtoplaygame .modal-header .close {
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  filter: alpha(opacity=20);
  opacity: 1;
  margin: -1rem -1rem -1rem auto;
}
.howtoplaygame .modal-header h4 {
  font-size: 21px;
  font-weight: 400;
}
.modal-header {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.howtoplaygame .modal-body {
  position: relative;
  padding: 12px;
}
.howtoplaygame .modal-body .inner {
  padding: 10px;
}
.howtoplaygame .modal-body .innertop {
  background-color: #e2f8ff;
  padding: 15px;
  color: #004e77;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}
.howtoplaygame .modal-body .inner p {
  padding: 10px 0;
}
.howtoplaygame .modal-body .innerbottom {
  background-color: #fffddd;
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 400;
}
.howtoplaygame .modal-body .innerbottom .heading {
  background-color: #ea4518;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  padding: 6px 18px;
}
.howtoplaygame .modal-body .innerbottom .content {
  background-color: #fffddd;
  color: #1e1e1e;
  font-size: 15px;
  padding: 10px 15px;
}
.howtoplaygame .modal-body .innerbottom .content ul {
  margin: 0;
}
.howtoplaygame .modal-body .innerbottom .content ul li {
  padding: 8px 0;
}
.howtoplaygame .modal-body .innerbottom .content ul li span.points {
  background: #e32f11;
  background: -moz-linear-gradient(top, #e32f11 0, #f15c20 100%);
  background: -webkit-linear-gradient(top, #e32f11 0, #f15c20 100%);
  background: linear-gradient(to bottom, #e32f11 0, #f15c20 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e32f11', endColorstr='#f15c20', GradientType=0 );
  -ms-transition: all 0.3s ease-in-out;
  border: 4px solid #d2b452;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 6px;
  text-align: center;
  width: 16%;
  float: left;
  line-height: 1.2;
  display: table;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
.howtoplaygame .modal-body .innerbottom .content ul li span.points span {
  display: table-cell;
  vertical-align: middle;
}
.howtoplaygame .modal-body .innerbottom .content ul li span.points_txt {
  float: left;
  padding: 0 18px;
  width: 84%;
  color: #1e1e1e;
}
.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  height: 200px;
}

.howtoplaygame .modal-dialog {
  max-width: 1000px !important;
}
.tambola-result {
  padding: 0;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.tambolawinners-details {
  padding: 0;
}
.tambola-winners-title {
  text-transform: uppercase;
  padding: 15px 15px 15px 17px;
  background-color: #0da7db;
  border-radius: 5px 5px 0 0;
  color: #fff;
  line-height: 1;
}
.tambola-winners-title h1 {
  color: #fff;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  text-transform: capitalize;
}
.tambola-winners-title h1 .back-button {
  color: #0da7db;
  margin-right: 12px;
  font-weight: 400;
  display: inline-block;
}
.last-gamewinners {
  padding: 11px 0 0;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.last-gamewinners .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: none;
}
.last-gamewinners .tambola-winners-list th {
  color: #0da7db;
  font-weight: 400;
  padding: 6px 6px 14px 22px;
  text-transform: uppercase;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #e2f8ff;
}
.last-gamewinners .table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}
.tambola-result .tambola-winners-list td {
  border-top: none;
  padding: 11px 6px 6px 22px;
}
.winners-pic {
  float: left;
  margin: 0 14px 0 0;
}
.winners-pic img {
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.winners-name {
  float: left;
  margin: 10px 0 0;
}
.addfunds .claim-nowleft .btn-claim,
.claim-nowright .btn-claim {
  padding: 0.375rem 0.75rem;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e7eaed;
  border-radius: 0.375rem;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.small-shadow {
  webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
}
.funds .signin_username .input-group {
  padding: 8px;
}
.funds .signin_username .input-group-addon {
  padding: 12px 12px;
}
.funds .signin_username .input-group {
  border-radius: 4px;
}
.funds .signin_username .mck-form-group .form-control {
  border-left: none !important;
  font-size: 18px;
}
.funds .claim-nowleft .btn-claim,
.claim-nowright .btn-claim {
  padding: 0.875rem 2.75rem;
}
.funds .claim-nowleft {
  float: none;
  margin-top: 25px !important;
}
.subtitletext {
  font-size: 22px;
  margin-top: 15px;
  color: #001563;
}
.brleft {
  border-left: 2px solid #dee2e6 !important;
}
.pl-60 {
  padding-left: 60px;
}
.p-30 {
  padding: 30px;
}
.prblem-report {
  margin-right: 10px;
}
.claim-nowleft .btn-claim2,
.claim-nowright .btn-claim2 {
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #fff;
  /* padding: 12px; */
  padding: 0.375rem 0.75rem;
  width: 100%;
}
.addfunds .claim-nowleft .btn-claim,
.claim-nowright .btn-claim {
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #fff;

  padding: 0.375rem 0.75rem;
  width: 100%;
}
.gamecomplete {
  width: 30%;
  height: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.fr {
  float: right !important;
}

.addfunds .claim-nowleft .btn-claim3,
.claim-nowright .btn-claim3 {
  font-size: 16px;
  padding: 14px;
  margin-bottom: 5px;
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #fff;
  width: 100%;
  padding: 0.375rem 0.75rem;
}
.trth {
  width: 8%;
}
.claim-nowleft .btn-claim,
.claim-nowright .btn-claimbuytick {
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #fff;
  /* padding: 8px; */
  width: 100%;
}
.claim-nowleft .btn-claim,
.claim-nowright .btn-claimbuytick {
  background-color: #f5540e;
  line-height: 1;
  padding: 0.375rem 0.75rem;
}
.mtop {
  margin-top: 1.5rem;
}
