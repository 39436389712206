a,
a img {
	outline: 0 !important;
}

canvas,
footer,
header,
menu,
nav,
section {
	display: block;
}

.mega-dropdown-menu > li > ul > li,
dd,
dl,
dt,
li,
ol,
ul {
	list-style: none;
}

#navigation .navbar-nav > li > a,
.btn-viewall,
.easyreward,
.joinforfree,
.participate,
.redeem {
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

#navigation .navbar-nav > li > a,
.btn-viewall,
.mck-games a {
	-webkit-transition: all 0.3s ease-in-out;
}

.mega-dropdown-menu > li ul > li > a:focus,
.mega-dropdown-menu > li ul > li > a:hover,
.movie-details .movie-name a,
a,
a:focus,
a:visited {
	text-decoration: none;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Open Sans", sans-serif !important;
}

a,
abbr,
address,
area,
article,
aside,
audio,
b,
base,
bdi,
bdo,
blockquote,
br,
button,
canvas,
caption,
cite,
code,
col,
colgroup,
command,
datalist,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
head,
header,
hgroup,
hr,
html,
i,
iframe,
img,
input,
ins,
kbd,
keygen,
li,
link,
map,
mark,
menu,
meta,
meter,
nav,
noscript,
object,
ol,
optgroup,
option,
ouput,
p,
param,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
script,
section,
select,
small,
source,
span,
strong,
style,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
title,
tr,
track,
u,
ul,
var,
video,
wbr {
	margin: 0;
	padding: 0;
	border: none;
}

table {
	table-layout: fixed;
}

.btn-viewall,
.logo-footer a img,
.mck-games a img,
.mck-moviereview a img {
	outline: 0;
}

a,
area,
img,
link,
menu,
nav {
	border: none;
}

table,
td,
tr {
	border-collapse: collapse;
	border-spacing: 0;
}

textarea {
	resize: none;
}

.clearfix {
	zoom: 1;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	font-size: 0;
	line-height: 0;
	height: 0;
}

.clear,
.clearboth {
	clear: both;
}

.launching_msg {
	font-size: 14px;
}

.launching_msg .modal-header {
	background-color: #0da7db;
	color: #fff;
	font-weight: 400;
	border-radius: 4px 4px 0 0;
}

.loading_overlay {
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.32);
	z-index: 9999999;
}

.loading_overlay_content {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 25%;
	transform: translate(-50%, -50%);
	text-align: center;
	display: inline-block;
	color: #fff;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
}

.loading_overlay_txt {
	padding: 5px 0 0 0;
}

.launching_msg .modal-header .close {
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-shadow: none;
	filter: alpha(opacity=20);
	opacity: 1;
}

.launching_msg .modal-body .launchingmsg_top {
	padding-bottom: 10px;
}

.gamestitle h1 a {
	color: #001563;
}

.launching_msg .modal-body .launchingmsg_bottom {
	padding-top: 10px;
	color: #004e77;
}

.launching_msg .modal-body .launchingmsg_btm {
	color: #004e77;
}

.launching_msg .modal-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #0da7db;
	border-radius: 4px;
}

.launching_msg .modal-header {
	padding: 15px 15px 8px;
	border-bottom: none;
	font-size: 14px;
}

.launching_msg .modal-body {
	background-color: #e2f8ff;
	color: #1e1e1e;
	font-size: 13px;
	padding: 10px 15px;
	border-radius: 0 0 4px 4px;
}

.launching_msg .modal-dialog {
	margin: 105px auto;
}

.launching_msg .modal-header h4 {
	font-size: 14px;
}

#middle-ads {
	margin: 0 0 20px;
	overflow: hidden;
}

header {
	width: 100%;
	margin: 0;
	padding: 0;
	background-color: #001563;
	/* background: url(../images/header-bg.gif) center top repeat-y; */
	background-size: contain;
	min-height: 54px;
}

.googlemobile-add {
	display: none;
	width: 100%;
	margin: 10px auto;
}

.mck-mobile-header {
	display: none;
	margin: 0;
	background-color: #001563;
	/* background: url(../images/mobile-header-bg.gif) left top repeat-y #f12040; */
	background-size: contain;
}

.logo-left {
	padding: 0;
	margin: 0;
	float: left;
}

.logo-left a {
	padding: 12px 0 12px 4px;
	display: block;
}

.logo-left a img {
	border: none;
}

.user-right {
	float: right;
	margin-top: -7px;
}

.user-notification {
	margin: 0;
	color: #fff;
	display: block;
	font-weight: 400;
	position: relative;
}

.user-notification .fa {
	font-size: 20px;
	font-weight: 400;
}

.totalnotification {
	position: absolute;
	width: 16px;
	height: 14px;
	right: -5px;
	top: 14px;
	background-color: #0dc30e;
	color: #fff;
	font-weight: 700;
	font-size: 11px;
	line-height: 1;
	padding: 1px 0;
	border-radius: 1px;
	text-align: center;
}

.navbar-nav {
	float: none;
	margin: 0;
	padding: 0;
}

.user-right .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
	position: absolute;
	right: 0;
	left: auto;
	width: 230px;
	padding: 0;
	margin: 2px 0 0;
	top: 100%;
	font-size: 12px;
	box-shadow: none;
	border-color: #eee;
}

.user-right .navbar-nav > li.prblem-report {
	padding-top: 7px;
}

.user-right .navbar-nav > li.prblem-report a .fa {
	margin-right: 4px;
}

.navbar-nav > .messages-menu > .dropdown-menu > li.header,
.navbar-nav > .tasks-menu > .dropdown-menu > li.header,
.user-right .navbar-nav > .notifications-menu > .dropdown-menu > li.header {
	background-color: #fff;
	padding: 7px 10px;
	border-bottom: 1px solid #f4f4f4;
	color: #444;
	font-size: 13px;
	border-radius: 4px 4px 0 0;
}

.user-right
	.navbar-nav
	> .notifications-menu
	> .dropdown-menu
	> li
	.menu
	> li
	> a {
	color: #777;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 10px;
	display: block;
}

.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a:hover,
.user-right
	.navbar-nav
	> .notifications-menu
	> .dropdown-menu
	> li
	.menu
	> li
	> a:hover {
	background: 0 0;
	text-decoration: none;
	color: #262626;
}

.dropdown-menu > li > a,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a,
.user-right
	.navbar-nav
	> .notifications-menu
	> .dropdown-menu
	> li
	.menu
	> li
	> a {
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid #f4f4f4;
}

.dropdown-menu > li > a > .fa,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .fa,
.navbar-nav
	> .notifications-menu
	> .dropdown-menu
	> li
	.menu
	> li
	> a
	> .glyphicon,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .ion {
	width: 20px;
	color: #f12041;
	margin-right: 2px;
}

.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
	font-size: 12px;
	background-color: #fff;
	padding: 7px 10px;
	border-bottom: 1px solid #eee;
	color: #444 !important;
	text-align: center;
	border-radius: 0 0 4px 4px;
}

.navbar-custom-menu {
	margin: 0;
}

.notifications-menu {
	display: block;
	margin: 0;
}

.navbar-nav > li {
	display: block;
	padding: 0;
}

.navbar-nav > li:last-child {
	margin-right: 0;
}

.navbar-nav > li > a {
	padding: 15px 10px 5px 4px;
	margin: 0;
	display: block;
	color: #fff;
	float: left;
}

.notifications-menu .dropdown-toggle {
	padding: 21px 10px 18px;
}

.navbar-nav > li > a:focus,
.navbar-nav > li > a:hover {
	background-color: transparent;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.notifications-menu .nav .open > a {
	background-color: transparent;
	border: none;
}

.navbar-nav > .user-menu .user-image {
	float: left;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	margin: -1px 4px 4px 0;
	border: 3px solid #cc152a;
}

.username {
	vertical-align: middle;
	margin-left: 3px;
	font-size: 16px;
	font-weight: 400;
	float: left;
	color: white;
	margin-top: 6px;
}
.report {
	color: white;
}
.spaceset {
	margin: 0 8px;
}

.dropdown-menu > li > a {
	color: #777;
	padding: 10px 15px;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
	background-color: transparent;
	background-image: none;
}

.user-points {
	background: url(../images/total-points-bg.png) center no-repeat;
	background-size: contain;
	text-transform: uppercase;
	font-weight: 700;
	padding: 9px 18px 8px 12px;
	margin: 16px 0 0;
	display: block;
	float: left;
	height: 29px;
	font-size: 12px;
	color: #fff;
	line-height: 1;
}

.mega-dropdown {
	position: static !important;
}

.mega-dropdown-menu {
	padding: 20px 0;
	width: 100%;
	box-shadow: none;
	-webkit-box-shadow: none;
}

.mega-dropdown-menu > li > ul {
	padding: 0;
	margin: 0;
}

.mega-dropdown-menu > li > ul > li > a {
	display: block;
	color: #222;
	padding: 3px 5px;
}

.mega-dropdown-menu .dropdown-header {
	font-size: 18px;
	color: #ff3546;
	padding: 5px 60px 5px 5px;
	line-height: 30px;
}

#navigation {
	margin: 0;
	padding: 0;
}

#navigation .navbar-nav {
	width: 100%;
	text-align: center;
}

#navigation .navbar {
	background-color: #fbfbfb;
	border: 1px solid #e3e3e3;
	color: #000508;
	font-weight: 600;
	text-transform: uppercase;
	border-radius: 0 0 4px 4px;
	min-height: 38px;
	margin-bottom: 18px;
}

.gamestitle,
.howittitle {
	border-bottom: 1px solid #f1223e;
}

#navigation .navbar-nav > li > a {
	padding: 15px 10px 13px;
	margin: 0;
	display: block;
	float: none;
	line-height: 1;
	font-size: 14px;
	color: #000508;
	position: relative;
}

#navigation .navbar-nav > li > a.active:after,
#navigation .navbar-nav > li > a:hover:after {
	content: "\f0d8";
	font-size: 16px;
	position: absolute;
	left: 10px;
	right: 10px;
	bottom: -4px;
	font-family: FontAwesome;
}

#navigation .navbar-nav > li > a.active {
	color: #f4471b;
}

#navigation .navbar-nav > li > a.active:after {
	color: #f54b17;
}

#navigation .navbar-nav > li > a.active:hover,
#navigation .navbar-nav > li > a:hover {
	color: #f4471b;
}

#navigation .navbar-nav > li > a:hover:after {
	color: #f54b17;
}

#navigation .navbar-collapse {
	padding-right: 15px;
	padding-left: 15px;
}

#navigation .navbar-nav > li {
	float: none;
	display: inline-block;
}

#navigation .navbar-nav > li:last-child {
	float: none;
	display: inline-block;
	margin-right: 0;
}

#navigation .dropdown-menu > li > a {
	font-size: 13px;
	font-weight: 400;
	color: #262626;
}

#navigation .dropdown-menu > li > a:hover {
	font-weight: 400;
	background: #ffe0c5;
}

#navigation .dropdown-menu {
	padding: 10px;
}

#navigation .mega-dropdown-menu .dropdown-header {
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
}

#navigation .mega-dropdown-menu > li > ul > li > a {
	font-weight: 400;
	font-size: 14px;
	color: #262626;
}

#navigation .mega-dropdown-menu > li > ul > li > a:hover {
	font-weight: 400;
	background: #ffe0c5;
}

#navigation .mega-dropdown-menu > li > ul {
	padding-bottom: 12px;
}

#navigation .mega-dropdown-menu > li > ul > li {
	text-transform: capitalize;
	margin-bottom: 2px;
}

.footer-link h3,
.gamestitle,
.howittitle {
	text-transform: uppercase;
}

#add-vertical {
	text-align: center;
	margin: 0 auto 18px;
	padding: 0;
	overflow: hidden;
}

#mid-section {
	margin: 0;
	padding: 0;
}

.topslider_common {
	margin: 0;
}

.topslider_column {
	width: 70%;
	float: left;
}

.topadd_column {
	width: 28%;
	float: right;
	text-align: right;
	max-width: 315px;
}

.square-add {
	margin: 0;
	border-radius: 4px;
}

.square-add img {
	margin: 0;
}

.add-areaupper {
	position: relative;
}

.add-totalpointsleft,
.add-totalpointsright {
	background-color: #f3352c;
	padding: 5px 15px;
	color: #fff;
	font-size: 14px;
	line-height: 1;
	font-weight: 400;
	position: absolute;
	text-align: center;
	bottom: 0;
}

.add-areaupper img {
	width: 100%;
	border-radius: 4px;
}

.add-totalpointsleft {
	border-radius: 0 2px;
	left: 0;
}

.add-totalpointsright {
	border-radius: 2px 0;
	right: 0;
}

.topaddsection {
	padding: 19px 0 20px;
}

#gameswidget {
	padding: 0 0 20px;
}

.gamestitle {
	margin: 0;
	padding: 0 0 8px;
}

.gamestitle h1 {
	color: #001563;
	font-weight: 300;
	font-size: 26px;
	margin: 0;
	padding: 0;
}

.btn-viewall {
	background-color: #fff;
	border: 1px solid #f1223e;
	font-size: 13px;
	font-weight: 400;
	color: #001563;
	padding: 5px 10px;
	border-radius: 3px;
}

.btn-viewall:hover {
	background-color: #f1223e;
	color: #fff;
}

.howittitle {
	margin: 0;
	padding: 0 0 8px;
}

.howittitle h1 {
	color: #001563;
	font-weight: 300;
	font-size: 26px;
	margin: 0;
	padding: 0;
}

.mckhow-it {
	padding: 14px 0 0;
}

.howitleft {
	margin: 0;
	padding: 18px 0 0;
}

.howitlefttoparea {
	position: relative;
	min-height: 197px;
}

.howitleftbottom {
	position: relative;
	min-height: 1px;
}

.easyreward,
.joinforfree {
	border-radius: 50%;
	color: #fff;
	width: 197px;
	height: 197px;
	position: absolute;
	top: 0;
}

.joinforfree {
	background-color: #da4551;
	left: 0;
	padding: 30px 30px 47px;
	text-align: center;
}

.joinforfree:hover {
	background-color: #d50011;
}

.easyreward:hover {
	background-color: #e15300;
}

.participate:hover {
	background-color: #eb1b2b;
}

.redeem:hover {
	background-color: #ff9e00;
}

.joinforfreeicon {
	margin: 0;
	padding: 0 0 12px;
	text-align: center;
}

.joinforfree p {
	font-weight: 400;
	font-size: 15px;
	line-height: 1.4;
}

.easyreward {
	background-color: #ee6515;
	right: 175px;
	padding: 28px 5px 47px;
	text-align: center;
}

.participate,
.redeem {
	border-radius: 50%;
	color: #fff;
	width: 197px;
	height: 197px;
	position: absolute;
	top: -55px;
	text-align: center;
}

.easyrewardicon {
	margin: 0;
	padding: 0 0 12px;
	text-align: center;
}

.easyreward p {
	font-size: 14px;
	line-height: 1.4;
	font-weight: 600;
}

.participate {
	background-color: #f06570;
	left: 188px;
	padding: 30px 20px 47px 15px;
}

.participateicon {
	margin: 0;
	padding: 0 0 12px;
	text-align: center;
}

.participate p {
	font-weight: 400;
	font-size: 15px;
	line-height: 1.4;
}

.redeem {
	background-color: #fcb644;
	right: -15px;
	padding: 28px 5px 47px;
}

.howitleftbottomarea,
.mck-games {
	position: relative;
}

.redeemicon {
	margin: 0;
	padding: 0 0 12px;
	text-align: center;
}

.redeem p {
	font-size: 14px;
	line-height: 1.4;
	font-weight: 600;
}

.howitright {
	margin: 0;
	text-align: right;
}

.gamepoints,
.movie-details,
.movie-details .star-rating,
.product-details,
.reviewpoints {
	text-align: center;
}

.howitright img {
	padding-top: 10px;
}

.games-widgetbox {
	padding: 20px 0 0;
}

.mck-games {
	margin: 0;
}

.gamepoints,
.gm-title-strip {
	color: #fff;
	font-size: 14px;
	position: absolute;
	line-height: 1;
}

.games-widgetbox .menu {
	padding-top: 22px;
	display: none;
}

.mck-games a {
	display: block;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}

.btn-info,
.mck-moviereview {
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

.gamepoints {
	background-color: #f3352c;
	border-radius: 0 2px;
	padding: 5px 7px;
	font-weight: 400;
	right: 0;
	top: 0;
	display: inline-block;
}

.gm-title-strip {
	padding: 12px 12px 12px 22px;
	font-weight: 300;
	width: 100%;
	display: block;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.71);
}

#movie-review {
	padding: 0 0 20px;
}

.movie-reviewbox {
	padding: 20px 0 0;
}

.mck-moviereview {
	position: relative;
	border: 1px solid #ececec;
	margin-bottom: 15px;
}

.btn-info {
	color: #001563;
	border: 1px solid #001563;
	background-image: none;
	background-color: #fff;
	border-radius: 2px;
	font-family: Roboto, sans-serif;
	text-shadow: none;
	padding: 5px 14px;
	font-size: 13px;
	box-shadow: none;
	margin-top: 12px;
}

.btn-info:hover {
	background-color: #f1223e;
	color: #fff;
	border: 1px solid #001563;
	background-image: none;
}

.mck-moviereview:hover {
	border: 1px solid #f1223e;
}

.mck-moviereview a {
	display: block;
}

.reviewpoints {
	background-color: #f3352c;
	border-radius: 0 0 0 2px;
	padding: 5px 10px;
	color: #fff;
	font-size: 14px;
	line-height: 1;
	font-weight: 400;
	position: absolute;
	right: 0;
	top: 0;
	display: inline-block;
}

.mck-games a img {
	width: 100%;
	-webkit-transition: all 0.5s linear;
	transition: all 0.5s linear;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

.more-games {
	padding: 22px 0 0;
	display: none;
}

.mck-games a:hover img {
	-webkit-transform: scale3d(1.1, 1.1, 1);
	transform: scale3d(1.1, 1.1, 1);
}

.moviebanner {
	margin: 0;
}

.moviebanner img {
	width: 100%;
}

.movie-details {
	padding: 12px;
}

.movie-details .movie-name,
.movie-details .movie-type {
	color: #404040;
	padding: 0 0 8px;
	font-family: Roboto, sans-serif;
	line-height: 1;
}

.movie-details .movie-name {
	font-weight: 700;
	font-size: 19px;
}

.movie-details .movie-name a {
	color: #404040;
}

.movie-details .movie-type {
	font-weight: 400;
	font-size: 14px;
}

.movie-details .total-votes {
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 1;
	color: #f33e24;
	padding: 0 0 12px;
}

.movie-details span.outer {
	background: url(../images/rating.png) 0 -19px repeat-x rgba(0, 0, 0, 0);
	display: block;
	height: 20px;
	width: 75px;
	margin: 0 auto;
}

.movie-details span.inner {
	background: url(../images/rating.png) repeat-x;
	display: block;
	height: 20px;
	width: 75px;
	float: left;
	margin: 0 auto;
}

.movie-details .star-rating span {
	margin: 0 1px 0 0;
	display: inline-block;
}

.movie-details .star-rating .filled {
	background-color: #ffd71f;
	border-radius: 50%;
}

.movie-details .star-rating .filled .fa {
	color: #414100;
	font-size: 12px;
	line-height: 1;
}

.movie-details .star-rating .blank {
	background-color: #e8e8e8;
	border-radius: 50%;
}

.movie-details .star-rating .blank .fa {
	color: #a3a3a3;
	font-size: 12px;
	line-height: 1;
}

#product-review {
	padding: 0 0 42px;
}

.product-reviewbox {
	padding: 30px 0 0;
}

.mck-productreview {
	position: relative;
	border: 1px solid #ececec;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	overflow: hidden;
}

.mck-productreview:hover {
	border: 1px solid #f1223e;
}

.mck-productreview a {
	outline: 0;
	display: block;
}

.mck-productreview a img {
	display: block;
}

.product-details .product-name a,
.product-details .product-type a {
	color: #313131;
	display: inline-block;
	text-decoration: none;
}

.productbanner {
	margin: 0;
	height: 304px;
}

.productbanner img {
	width: 100%;
}

.product-details {
	padding: 12px;
	background-color: #f8f8f8;
}

.product-details .product-name {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
	color: #313131;
	padding: 0 0 5px;
	height: 50px;
}

.product-details .product-type {
	font-weight: 400;
	font-size: 17px;
	line-height: 1;
	color: #313131;
	padding: 0 0 9px;
}

.product-details .total-votes {
	padding: 0 0 10px;
	min-height: 41px;
}

.product-details .total-votes span {
	font-weight: 600;
	font-size: 21px;
	line-height: 1;
	color: #fff;
	padding: 5px 10px;
	background-color: #ee6515;
	text-align: center;
	border-radius: 2px;
	display: block;
	width: 28%;
	margin: 0 auto;
}

.product-details .previews {
	color: #f3352c;
	font-weight: 400;
	font-size: 15px;
	text-decoration: underline;
}

.product-details .previews a {
	color: #f3352c;
}

#howit-works {
	padding: 0;
}

footer {
	width: 100%;
	margin: 0;
	padding: 90px 0 0;
}

.footer-top {
	background-color: #464646;
}

.footer-top-inner {
	padding: 48px 20px 30px;
}

.footer-link {
	color: #fff;
}

.footer-link h3 {
	margin: 0;
	font-weight: 400;
	font-size: 18px;
	padding-bottom: 15px;
}

.footer-link .linkarea {
	margin: 0;
	display: block;
}

.footer-link .linkarea li {
	display: block;
	padding-bottom: 14px;
	font-size: 14px;
	line-height: 1;
}

.footer-link .linkarea li a {
	display: inline-block;
	color: #fff;
}

.footer-bottom {
	background-color: #3d3d3d;
}

.footer-bottom-inner {
	padding: 30px 10px 30px 20px;
	color: #fff;
	font-size: 14px;
	line-height: 1;
}

.footer-bottom-inner p {
	font-size: 14px;
	padding-bottom: 8px;
}

.logo-footer {
	padding: 6px 5px 0 0;
}

.logo-footer a {
	display: block;
}

.loader,
.view-check .modal-body img.loader-image {
	width: 100px;
	height: 100px;
	display: block;
	margin: 0 auto;
}

.google-ad-bottom {
	padding: 30px 0 0;
}

@media only screen and (max-width: 1199px) {
	#navigation .navbar-nav > li {
		float: none;
		display: inline-block;
		margin-right: 0;
	}
}

@media only screen and (max-width: 992px) {
	#navigation .navbar-nav > li {
		float: none;
		display: inline-block;
		margin-right: 0;
	}
}

@media (max-width: 768px) {
	#navigation .navbar-nav > li > a {
		font-size: 9px;
	}
	#play-tambola {
		padding: 0;
		margin: 0 -7px;
	}
	.tambola-playbox .title {
		padding: 7px 10px 1px 14px;
	}
	.tgame-no {
		margin-bottom: 8px;
	}
	.game-details {
		font-size: 12px;
		padding: 3px 0 0 1px;
	}
	.game-details .col-xs-6 {
		margin-bottom: 12px;
	}
}

@media (max-width: 639px) {
	.logo-left,
	.user-right {
		float: none;
	}
	.gamestitle {
		margin: 12px 0 10px 0;
		padding: 0 0 6px;
	}
	#movie-review .col-xs-6 {
		padding-right: 12px;
		padding-left: 12px;
	}
	#navigation,
	header {
		display: none;
	}
	.logo-left,
	.mck-mobile-header,
	.user-right {
		display: block;
	}
	.productbanner {
		height: inherit;
	}
	.gamestitle {
		margin: 0;
		padding: 0 0 6px;
	}
	.logo-left {
		padding-top: 2px;
		margin-bottom: 8px;
		text-align: center;
	}
	.mck-moviereview,
	.mck-productreview {
		margin-bottom: 18px;
	}
	.user-right .navbar-nav > li {
		float: left;
		margin-right: 25px;
	}
	.topadd_column,
	.topslider_column {
		float: none;
		width: 100%;
	}
	#navigation .navbar .navbar-toggle {
		background-color: #f54d14;
	}
	#navigation .navbar .navbar-toggle .icon-bar {
		background-color: #fff;
	}
	#navigation .navbar-nav {
		text-align: left;
	}
	#navigation .navbar-nav > li {
		display: block;
		margin-right: 0;
	}
	#howit-works,
	#navigation .navbar-nav > li > a.active:after {
		display: none;
	}
	.topadd_column {
		text-align: center;
	}
	.mck-moviereview {
		height: 328px;
		overflow: hidden;
	}
	.add-areaupper,
	.mck-games {
		margin-bottom: 15px;
	}
	#add-vertical img {
		width: 100%;
	}
	.homeslider {
		margin-top: 15px;
	}
	.topaddsection {
		padding: 15px 0 0;
	}
	#gameswidget {
		padding: 0 0 5px;
	}
	.gamestitle h1 {
		font-size: 13px;
		padding: 2px 0 0;
		font-weight: 400;
	}
	.games-widgetbox,
	.movie-reviewbox,
	.product-reviewbox {
		padding: 22px 0 0;
	}
	.mck-games {
		min-height: 122px;
	}
	footer {
		padding: 0;
	}
	.footer-top-inner {
		padding: 20px 4px 14px;
		text-align: center;
	}
	.footer-link h3 {
		font-size: 14px;
		padding-bottom: 12px;
	}
	.footer-link .linkarea li {
		padding-bottom: 9px;
		font-size: 12px;
	}
	.footer-bottom-inner {
		padding: 8px 10px 10px 5px;
		color: #fff;
		font-size: 13px;
		line-height: 1;
	}
	.footer-bottom-inner p {
		font-size: 10px;
		padding-bottom: 3px;
		line-height: 1.2;
		text-align: center;
	}
	.logo-footer {
		padding: 5px 5px 0 0;
		text-align: center;
	}
	#product-review {
		padding: 0 0 34px;
	}
	.btn-viewall {
		font-size: 10px;
		padding: 2px 5px;
	}
	.movie-details .movie-name,
	.product-details .product-name,
	.product-details .product-type {
		font-size: 13px;
		line-height: 1.3;
	}
	.product-details .total-votes span {
		font-size: 13px;
		padding: 5px 10px;
		width: 70%;
	}
	.product-details .total-votes {
		min-height: 36px;
	}
	.reviewpoints {
		font-size: 10px;
	}
	.games-widgetbox .menu {
		display: block;
		padding-top: 0;
	}
	.gamestitle #showmenu,
	.googleadd-bottom,
	.googleadd-right,
	.googleadd-top {
		display: none;
	}
	.gm-title-strip {
		padding: 6px 4px 5px 6px;
		font-size: 11px;
		line-height: 1.2;
	}
	.product-details .product-name {
		height: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 2px;
	}
	#movie-review {
		padding: 0 0 5px;
	}
	.googlemobile-add {
		display: inline-block;
	}
	.product-details .previews {
		font-size: 13px;
	}
	#middle-ads {
		margin-bottom: 15px;
	}
	.google-ad-bottom {
		padding: 10px 0 20px;
	}
}

@media (max-width: 480px) {
	.mobile-ad-top {
		padding: 10px 0 10px 0;
	}
	.logo-left,
	.user-right {
		float: right;
	}
	.gamestitle {
		margin: 12px 0 10px 0;
		padding: 0 0 6px;
	}
	#movie-review .col-xs-6 {
		padding-right: 12px;
		padding-left: 12px;
	}
	#navigation,
	header {
		display: none;
	}
	.logo-left,
	.mck-mobile-header,
	.user-right {
		display: block;
	}
	.productbanner {
		height: inherit;
	}
	.gamestitle {
		margin: 0;
		padding: 0 0 6px;
	}
	.logo-left {
		padding-top: 2px;
		margin-bottom: 8px;
		text-align: center;
	}
	.mck-moviereview,
	.mck-productreview {
		margin-bottom: 18px;
	}
	.user-right .navbar-nav > li {
		float: left;
		margin-right: 25px;
	}
	.topadd_column,
	.topslider_column {
		float: none;
		width: 100%;
	}
	#navigation .navbar .navbar-toggle {
		background-color: #f54d14;
	}
	#navigation .navbar .navbar-toggle .icon-bar {
		background-color: #fff;
	}
	#navigation .navbar-nav {
		text-align: left;
	}
	#navigation .navbar-nav > li {
		display: block;
		margin-right: 0;
	}
	#howit-works,
	#navigation .navbar-nav > li > a.active:after {
		display: none;
	}
	.topadd_column {
		text-align: center;
	}
	.mck-moviereview {
		height: 328px;
		overflow: hidden;
	}
	.add-areaupper,
	.mck-games {
		margin-bottom: 15px;
	}
	#add-vertical img {
		width: 100%;
	}
	.homeslider {
		margin-top: 15px;
	}
	.topaddsection {
		padding: 15px 0 0;
	}
	#gameswidget {
		padding: 0 0 5px;
	}
	.gamestitle h1 {
		font-size: 13px;
		padding: 2px 0 0;
		font-weight: 400;
	}
	.games-widgetbox,
	.movie-reviewbox,
	.product-reviewbox {
		padding: 22px 0 0;
	}
	.mck-games {
		min-height: 122px;
	}
	footer {
		padding: 0;
	}
	.footer-top-inner {
		padding: 20px 4px 14px;
		text-align: center;
	}
	.footer-link h3 {
		font-size: 14px;
		padding-bottom: 12px;
	}
	.footer-link .linkarea li {
		padding-bottom: 9px;
		font-size: 12px;
	}
	.footer-bottom-inner {
		padding: 8px 10px 10px 5px;
		color: #fff;
		font-size: 13px;
		line-height: 1;
	}
	.footer-bottom-inner p {
		font-size: 10px;
		padding-bottom: 3px;
		line-height: 1.2;
		text-align: center;
	}
	.logo-footer {
		padding: 5px 5px 0 0;
		text-align: center;
	}
	#product-review {
		padding: 0 0 34px;
	}
	.btn-viewall {
		font-size: 10px;
		padding: 2px 5px;
	}
	.movie-details .movie-name,
	.product-details .product-name,
	.product-details .product-type {
		font-size: 13px;
		line-height: 1.3;
	}
	.product-details .total-votes span {
		font-size: 13px;
		padding: 5px 10px;
		width: 70%;
	}
	.product-details .total-votes {
		min-height: 36px;
	}
	.reviewpoints {
		font-size: 10px;
	}
	.games-widgetbox .menu {
		display: block;
		padding-top: 0;
	}
	.gamestitle #showmenu,
	.googleadd-bottom,
	.googleadd-right,
	.googleadd-top {
		display: none;
	}
	.gm-title-strip {
		padding: 6px 4px 5px 6px;
		font-size: 11px;
		line-height: 1.2;
	}
	.product-details .product-name {
		height: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 2px;
	}
	#movie-review {
		padding: 0 0 5px;
	}
	.googlemobile-add {
		display: inline-block;
	}
	.product-details .previews {
		font-size: 13px;
	}
	#middle-ads {
		margin-bottom: 15px;
	}
	.google-ad-bottom {
		padding: 10px 0 20px;
	}
	.tambola-playbox .title {
		padding: 7px 9px 5px;
	}
	.tgame-no {
		text-align: left;
		font-size: 10px;
		padding: 4px 0 0;
	}
	.tambola-playbox .game-details {
		font-size: 12px;
		padding: 3px 0 0 6px;
	}
	.icon img {
		width: 14px;
		height: 13px;
	}
	.icon-p {
		margin: -1px 9px 2px 0;
		float: left;
	}
	.claimedby,
	.icon-p {
		display: inline-block;
	}
	.icon-p img,
	.icon-t img {
		width: 13px;
		height: 13px;
	}
	.game-details .fa {
		margin-right: 7px;
	}
	.game-details .col-xs-6 {
		margin-bottom: 10px;
	}
}
